import { ThemeColor } from "styles";

type companySpecificOnboardingStep = {
  Company: string;
  Id: string;
  Slug: string;
  Color: ThemeColor;
};

// Move to db in future if more companies are added
export const companiesSpecificOnboardingStep: companySpecificOnboardingStep[] = [
  {
    Company: 'Vision',
    Id: '08dc32e2-a0d3-4da5-8f16-9e53038f6809',
    Slug: 'vision-step',
    Color: 'purple'
  },
  {
    Company: 'Felix AB',
    Id: '08dc3839-a5ef-4bdf-893c-523a27e754b4',
    Slug: 'vision-step',
    Color: 'purple'
  },
]

