import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import CompetitionCard from 'components/cards/CompetitionCard';
import Loader from 'components/UI/Loader';
import Heading, { Tag } from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import { IconType } from 'components/UI/Icon';
import ListMenu from 'components/UI/ListMenu';

import {
  localizationSelector,
  setLayoutHeader,
  useGetActiveContestsQuery,
  useGetMembershipSettingsQuery,
} from 'store';
import { getImage } from 'utils/asset';
import { filterContests } from 'utils/contest';
import { ContentWidth, MediumGap } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import { ButtonLink, CardList } from './styles';
import { useNavigate } from 'react-router-dom';

const Compete: FC = () => {
  const intl = useIntl();
  const getText = useLocalizedText();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const settings = useGetMembershipSettingsQuery();
  const { language } = useAppSelector(localizationSelector);

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'pageCompeteTitle',
          defaultMessage: 'Compete',
          description: 'Compete page title',
        }),
      })
    );
  }, [dispatch, intl]);

  // Hooks
  const active = useGetActiveContestsQuery({
    language: language?.languageCode,
  });

  const notAvailableForCompetitionsMessage = useMemo(() => {
    if (!settings.data?.availableForContests) {
      return (
        <Fragment>
          <p>
            <FormattedMessage
              id="pageCompeteNotAvailableForCompetitions"
              defaultMessage="Din användarprofil är för närvarande dold, vilket innebär att andra inte kan bjuda in dig till utmaningar. "
              description="Empty state for competitions"
            />
            {' '}
            <ButtonLink onClick={() => navigate('/profile')}>
              <FormattedMessage
                id="pageCompeteNotAvailableForCompetitionsLink"
                defaultMessage="Vill du bli synlig? Klicka här för att justera din tillgänglighet!"
                description="Link to profile for visibility adjustment"
              />
            </ButtonLink>
          </p>
        </Fragment>
      )
    }
  }, [settings.data?.availableForContests, navigate]);

  // Invite competitions
  const inviteCompetitions = useMemo(() => {
    const list = active.data?.filter((item) => !item.accepted) || [];
    if (!list.length) {
      return null;
    }

    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteInvitationsTitle"
            defaultMessage="Invitations"
            description="Section title for invitations"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/invitations/${item.id}`}
              accepted={item.accepted}
              type={item.type}
              buttonText="Join"
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  // Active competitions
  const activeCompetitions = useMemo(() => {
    const list = filterContests('Competition', true, false, active.data, true);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteActiveCompetitionsTitle"
            defaultMessage="Active competitions"
            description="Section title for active competitions"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/competitions/${item.id}`}
              accepted={item.accepted}
              type={item.type}
              buttonText="Join"
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  // Upcoming competitions
  const upcomingCompetitions = useMemo(() => {
    const list = filterContests('Competition', true, false, active.data, false);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteUpcomingCompetitionsTitle"
            defaultMessage="Upcoming competitions"
            description="Section title for upcoming competitions"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/competitions/${item.id}`}
              accepted={item.accepted}
              type={item.type}
              buttonText="Join"
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  // Upcoming challenges
  const upcomingChallenges = useMemo(() => {
    const list = filterContests('Challenge', true, false, active.data, false);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteUpcomingChallengesTitle"
            defaultMessage="Upcoming challenges"
            description="Section title for upcoming challenges"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/challenges/${item.id}`}
              accepted={item.accepted}
              type={item.type}
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  // Active challenges
  const activeChallenges = useMemo(() => {
    const list = filterContests('Challenge', true, false, active.data, true);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteActiveChallengesTitle"
            defaultMessage="Active challenges"
            description="Section title for active challenges"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/challenges/${item.id}`}
              accepted={item.accepted}
              type={item.type}
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active.data]);

  // Completed competitions
  const completedCompetitions = useMemo(() => {
    const list = filterContests('Competition', true, true, active.data);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <MediumGap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteCompletedCompetitionsTitle"
            defaultMessage="Completed competitions"
            description="Section title for completed competitions"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/competitions/${item.id}`}
              accepted={item.accepted}
              type={item.type}
              buttonText="Join"
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  // Completed challenges
  const completedChallenges = useMemo(() => {
    const list = filterContests('Challenge', true, true, active.data);
    if (!list.length) {
      return null;
    }
    return (
      <Fragment>
        <MediumGap />
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageCompeteCompletedChallengesTitle"
            defaultMessage="Completed challenges"
            description="Section title for completed challenges"
          />
        </Heading>
        <CardList>
          {list.map((item, i) => (
            <CompetitionCard
              key={`${item.id}-${i}`}
              title={getText(item.title)}
              image={getImage(item.image)}
              wellrPoints={item.wellrPoints}
              link={`${routes.COMPETE}/challenges/${item.id}`}
              accepted={item.accepted}
              type={item.type}
              buttonText="Join"
            />
          ))}
        </CardList>
        <MediumGap />
      </Fragment>
    );
  }, [getText, active]);

  if (active.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!active.data) {
    return (
      <EmptyState iconType={IconType.Competition} padding>
        <FormattedMessage
          id="pageCompetitionsEmptyState"
          defaultMessage="No competitions found"
          description="Empty state for competitions"
        />
      </EmptyState>
    );
  }

  return (
    <ContentWidth noMargins isSurface>
      {
        <ListMenu
          menu={[
            {
              id: 1,
              text: intl.formatMessage({
                id: 'pageCompetitionsStartNewChallengeTitle',
                defaultMessage: 'Start a new challenge',
                description: 'Menu item for start new challenge',
              }),
              icon: IconType.Flag,
              link: routes.COMPETE_AVAILABLE_CHALLENGES,
            },
          ]}
        />
      }
      {notAvailableForCompetitionsMessage}
      <MediumGap />
      {inviteCompetitions}
      {upcomingCompetitions}
      {activeCompetitions}
      {upcomingChallenges}
      {activeChallenges}
      {completedCompetitions}
      {completedChallenges}
    </ContentWidth>
  );
};

export default Compete;
