import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints, ThemeColor } from 'styles';

type MenuProps = {
  inverted?: number;
  backgroundColor?: ThemeColor
};

type TabProps = {
  margin?: number;
};

export const Tabs = styled.ul<MenuProps>`
  display: flex;
  justify-content: space-around;
  min-height: 48px;
  border-radius: 16px;
  background-color: ${({ theme, inverted, backgroundColor }) => {
    if (inverted) {
      return 'rgba(255, 255, 255, 0.4)';
    }
    if (backgroundColor ){
      return theme.colors[backgroundColor];
    }
    return theme.colors.backgroundLight;
  }};
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const TabItem = styled(motion.li)<TabProps>`
  position: relative;
  margin: ${({ margin }) => margin ? `${margin}em` : '0'};
  flex: 1;
`;

export const TabText = styled(motion.span)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  padding: 12px 6px;
  z-index: 1;
  cursor: pointer;

  @media (min-width: ${breakpoints.m}) {
    gap: 8px;
    padding: 16px 8px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const TabNotification = styled(motion.div)`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
  bottom: 4px;
  background-color: ${({ theme }) => theme.colors.error};
`;

export const TabContainer = styled(motion.div)`
  position: absolute;
  border-radius: 16px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;


`;
