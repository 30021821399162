import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};

  h3 {
    margin: 0;
  }
`;

export const Body = styled.div`
  height: calc(100vh - 112px);
  overflow-y: auto;
  padding: 20px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`;
