// Auth
export const ROOT = '/';
export const LOGIN = '/login';
export const SIGN_UP = '/signup';
export const SIGN_UP_CONFIRM = '/signup/confirm';
export const REGISTER_INVITE = '/register-invite';
export const REGISTER_INVITE_SUCCESS = '/register-invite/success';
export const REGISTER_INVITE_CONFIRM = '/register-invite/confirm';
export const REGISTER_INVITE_REMOVE = '/register-invite/remove';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_CONFIRM = '/reset-password/confirm';
export const NEW_PASSWORD = '/new-password';

// User menu
export const WELCOME = '/welcome';
export const CUSTOM_ONBOARDING_STEP = '/welcome/custom-step';
export const ONBOARDINGSTEP3 = '/welcome/step3';
export const OVERVIEW = '/overview';
export const MY_HEALTH = '/my-health';
export const MY_HEALTH_REPORT = '/my-health/report';
export const TESTS = '/my-health/tests';
export const QUIZ = '/my-health/quiz';
export const PULSE_QUIZ = '/my-health/pulse-quiz';
export const TEST_HISTORY = '/my-health/test-history';
export const MEASURES = '/my-health/measures';
export const EXERCISES = '/exercise/exercises';
export const EXERCISES_CATEGORY = '/exercise/exercises/category';
export const EXERCISE = '/exercise';
export const EXERCISE_PROGRAM = '/exercise/programs';
export const EXERCISE_PROGRAMS_CATEGORY = '/exercise/programs/category';
export const COMPETE = '/compete';
export const COMPETE_AVAILABLE_CHALLENGES = '/compete/available-challenges';
export const COMPETE_AVAILABLE_CHALLENGES_CATEGORY = '/compete/available-challenges/category';
export const DIET = '/diet';
export const DIET_CATEGORY = '/diet/category';
export const RECIPE = '/diet/recipe';
export const INSPIRATION = '/inspiration';
export const INSPIRATION_ARTICLE = '/inspiration/article';
export const FAVORITES = '/favorites';
export const PROFILE = '/profile';
export const SETTINGS = '/settings';
export const NOTIFICATIONS = '/notifications';
export const NOTIFICATION = '/notifications/notification'
export const SETTINGS_PROFILE = '/settings/edit-profile';
export const SETTINGS_WEARABLE_DEVICE = '/settings/wearable-device-connection';
export const SETTINGS_MFA = '/settings/mfa';
export const SETTINGS_NOTIFICATION = '/settings/notification';
export const SETTINGS_REMINDER = '/settings/reminders';
export const PROFILE_ACHIEVEMENTS = '/profile/achievements';
export const REGISTER_COMPLETE = '/register-complete';
export const APP_REDIRECT = '/app-redirect';
export const SEARCH = '/search';
export const PROGRESS = '/progress'
export const PROGRESS_LEADERBOARD = '/progress/leaderboard'

// Admin menu
export const ADMIN_OVERVIEW = '/admin/overview';
export const ADMIN_HEALTH_REPORT = '/admin/health-report';
export const ADMIN_PULSE_TESTS = '/admin/pulse-tests';
export const ADMIN_COMPETITIONS = '/admin/competitions';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USER_STATS = '/admin/user-statistics';
export const ADMIN_COMPANY_USERS = '/admin/company-users';
export const ADMIN_ORGANISATION = '/admin/departments';
export const ADMIN_COMPANIES = '/admin/companies';
export const ADMIN_UMBRELLA_COMPANIES = '/admin/umbrella-companies';
export const ADMIN_MAINTENANCE = '/admin/maintenance';
export const ADMIN_COMPANIES_CREATE = '/admin/companies/create';
export const ADMIN_USER_CREATE = ADMIN_COMPANY_USERS + '/create';

