import AddCompanyOverviewContentModal from 'components/modals/AddCompanyOverviewContentModal';
import ActionCard from 'components/UI/Cards/ActionCard';
import EmptyState from 'components/UI/EmptyState';
import HeadingWithAdd from 'components/UI/HeadingWithAdd';
import { useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import useModal from 'hooks/useModal';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteCompanyContentMutation, useGetCompanyContentQuery } from 'store';
import { selectUser } from 'store/userState/userState';
import { FlexContainer } from 'styles';

const CompanyContentList: FC = () => {
  const intl = useIntl();
  const user = useAppSelector(selectUser);
  const getText = useLocalizedText();

  const companyId = user?.membership.companyId;

  const { modalOpen, onCloseModal, onOpenModal } = useModal();

  const result = useGetCompanyContentQuery(
    { companyId: companyId ?? '' },
    { skip: !companyId }
  );
  const [deleteContent] = useDeleteCompanyContentMutation();

  if (result.data == null ||
    result.isLoading) {
    return null;
  }

  return (
    <>
      <HeadingWithAdd
        title={intl.formatMessage({
          id: 'companyContentTitle',
          defaultMessage: 'Anpassa startsidan i Wellr',
          description: 'Umbrella company communications heading title',
        })}
        addTitle={intl.formatMessage({
          id: 'companyContentAddContentButton',
          defaultMessage: 'Add Content',
          description: 'Add communication button text',
        })}
        onAdd={onOpenModal}
      />
      {result.data.content && result.data.content.length > 0 ? (
        <>
          <FlexContainer gap={12} flexDirection="column">
            {result.data.content.map(({ slug, title }) => (
              <ActionCard
                title={getText(title)}
                key={slug}
                onDelete={() => deleteContent({ companyId: companyId!, slug: slug! })}
              />
            ))}
          </FlexContainer>
          <br />
        </>
      ) : (
        <EmptyState align="left" compact>
          <FormattedMessage
            id="companyContentDescription"
            defaultMessage="Här kan du skapa en personlig upplevelse för medarbetarna genom att skräddarsy innehållet i den översta delen av startsidan. Välj vilket Wellr-innehåll du vill lyfta fram för att inspirera och engagera här."
            description="Umbrella company communications empty state"
          />
        </EmptyState>
      )}
      <AddCompanyOverviewContentModal
        isOpen={modalOpen}
        onClose={onCloseModal}
        companyId={companyId!}
      />
    </>
  );
};

export default CompanyContentList;
