import {
  getEndpointURL,
  WellrEndpointDefinition,
  WellrEndpointPath,
} from 'utils/api';

/** Get company content **/
const companyContentPath: WellrEndpointPath =
  '/api/company/company/{companyId}/content';
const companyContentMethod = 'get';
type CompanyContent = WellrEndpointDefinition<
  typeof companyContentPath,
  typeof companyContentMethod
>;

export type GetCompanyContentType = {
  result: CompanyContent['responseBody'];
  queryArgs: Required<CompanyContent['request']['params']['path']>;
};

/**  Add company content  */
const addCompanyContentPath: WellrEndpointPath =
  '/api/company/company/{companyId}/content/{slug}';
const addCompanyContentMethod = 'post';
type AddCompanyContent = WellrEndpointDefinition<
  typeof addCompanyContentPath,
  typeof addCompanyContentMethod
>;

export type AddCompanyContentType = {
  result: AddCompanyContent['responseBody'];
  queryArgs: Required<AddCompanyContent['request']['params']['path']>;
  requestBody: Required<AddCompanyContent['request']['body']>;
};

/** Delete company content */
const deleteCompanyContentPath: WellrEndpointPath =
  '/api/company/company/{companyId}/content/{slug}';
const deleteCompanyContentMethod = 'delete';
type DeleteCompanyContent = WellrEndpointDefinition<
  typeof deleteCompanyContentPath,
  typeof deleteCompanyContentMethod
>;

export type DeleteCompanyContentType = {
  result: DeleteCompanyContent['responseBody'];
  queryArgs: Required<DeleteCompanyContent['request']['params']['path']>;
};

/** Get health choice stats */
const companyHealthQuizStatsPath: WellrEndpointPath =
  '/api/company/me/quiz/from-date';
const companyHealthQuizStatsMethod = 'get';
type CompanyHealthQuizStats = WellrEndpointDefinition<
  typeof companyHealthQuizStatsPath,
  typeof companyHealthQuizStatsMethod
>;

export type CompanyHealthQuizStatsType = {
  result: CompanyHealthQuizStats['responseBody'][number];
  queryArgs: Exclude<
    CompanyHealthQuizStats['request']['params']['query'],
    undefined
  >;
};

export const endpoints = {
  getHealthQuizStats: {
    path: (contestId: string) =>
      getEndpointURL(companyHealthQuizStatsPath, { contestId }),
    method: companyHealthQuizStatsMethod.toUpperCase(),
  },
  companyContent: {
    getCompanyContent: {
      path: (companyId: string) =>
        getEndpointURL(companyContentPath, { companyId }),
      method: companyContentMethod.toUpperCase(),
    },
    addCompanyContent: {
      path: (companyId: string, slug: string) =>
        getEndpointURL(addCompanyContentPath, { companyId, slug }),
      method: 'POST',
    },
    deleteCompanyContent: {
      path: (companyId: string, slug: string) =>
        getEndpointURL(deleteCompanyContentPath, { companyId, slug }),
      method: deleteCompanyContentMethod.toUpperCase(),
    },
  },
};
