import { AchievementResponseType } from 'models/achievement/achievement'
import { FormattedMessage } from 'react-intl'
import { localizationSelector } from 'store'
import { useNavigate } from 'react-router-dom'
import { getImageUrl } from 'constants/urls'
import { useAppSelector } from 'hooks/redux'
import Heading, { Tag } from 'components/UI/Heading'
import Loader from 'components/UI/Loader'
import useAchievementsData from 'hooks/useAchievementsData'
import React from 'react'
import * as routes from 'router/routes';
import { AccomplishmentContainer, BadgeContainer, BadgeImage, HeadingContainer, IconContainer, InnerButtonDetails } from './styles'
import Icon, { IconType } from 'components/UI/Icon'
import Button from 'components/UI/Button'

type Props = {}

const AccomplishmentsOverview = (props: Props) => {
  const { language } = useAppSelector(localizationSelector);
  const navigate = useNavigate();

  const {
    combinedBadges,
    countAllMyBadges,
    countAllBadges,
    loading,
  } = useAchievementsData();

  if (loading || !combinedBadges || !countAllBadges) {
    return <Loader />;
  }

  const handleBadgeClick = (badge: any) => {
    navigate(routes.PROGRESS + '/' + badge.slug, { state: { badge } });
  };

  const handleAllBadgesClick = () => {
    navigate(routes.PROGRESS);
  };


  return (
    <AccomplishmentContainer>

      <HeadingContainer>
        <Heading tag={Tag.H3} key="accomplishments-title">
          <FormattedMessage id="accomplishments" />
        </Heading>
        <Button background='transparent' color='black' onClick={() => handleAllBadgesClick()}>
          <InnerButtonDetails>
            {`${countAllMyBadges}/${countAllBadges}`}
            {' '}
            <FormattedMessage id="accomplishmentsEarned" />
            <IconContainer>
              <Icon type={IconType.Arrow} color="grayText" />
            </IconContainer>
          </InnerButtonDetails>
        </Button>
      </HeadingContainer>

      <BadgeContainer>
        {combinedBadges
          .map((badge: AchievementResponseType) => {
            const imageRef = badge.Image?.asset._ref;
            const imageUrl = getImageUrl(imageRef);
            return (
              <BadgeImage key={badge.slug}>
                <img
                  key={badge.slug}
                  src={imageUrl}
                  alt={badge.title[language?.languageCode ?? 'en'] || 'Title Unavailable'}
                  style={{
                    filter: badge.isLocked ? 'grayscale(100%)' : 'none',
                    opacity: badge.isLocked ? 0.6 : 1,
                  }}
                  onClick={() => handleBadgeClick(badge)}
                />
              </BadgeImage>
            );
          })
        }
      </BadgeContainer>

    </AccomplishmentContainer>
  )
}

export default AccomplishmentsOverview