import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

type Props = {
  isActive: boolean;
  isLocked: boolean;
};

type HandleProps = {
  $isLocked: boolean;
}
export const ToggleSwitch = styled.div<Props>`
  width: 48px;
  height: 24px;
  background: ${({ theme, isActive, isLocked }) =>
    theme.colors[isActive && !isLocked ? 'blue' : 'borderDark']};
  display: flex;
  justify-content: ${({ isActive }) => (isActive ? 'flex-end' : 'flex-start')};
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
`;

export const ToggleHandle = styled(motion.div) <HandleProps>`
  background: ${({ theme, $isLocked }) => ($isLocked ? theme.colors.pink : theme.colors.white)};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin: 2px;
`;
export const ToggleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  > * {
    flex: 1;
    margin: 0 1px;
    text-align: center;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 16px;
  }
`;

export const ToggleButton = styled.button<{ isActive?: boolean }>`
  padding: 10px 20px;
  font-size: 0.75rem;
  font-weight: 600;

  border-radius: 10px;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors['blue'] : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : 'black')};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors['blue'] :'#ddd')};

  @media (max-width: ${breakpoints.xs}px) {
    font-size: 11px;
  }
`;