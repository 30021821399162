import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 0px;


  @media (max-width: ${breakpoints.xs}px) {
    box-shadow: none;
    padding: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    box-shadow: none;
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 80px;
  width: 99%;
  margin: 0 auto;
  @media (max-width: ${breakpoints.xs}px) {
    width: 90%;
  }
  `;
export const BackHeaderButton = styled.button`
  display: flex;
  position: absolute;
  top: -7em;
  left: -0.8em;
  padding: 10px;
  text-align: center;
  align-items: center;
  background-color: transparent;
  gap: 0.2em;
  border: none;
  cursor: pointer;
  font-size: 16px;

  > svg {
    scale: 1.2;
    }

  @media (max-width: ${breakpoints.m}px) {
    position: relative;
    top: 0em;
    left: 0;
    width: 100%;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;




export const Heading = styled.h3`
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
  margin-bottom: 20px;
  font-size: 24px;
`;

export const ExpandButton = styled.div`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    text-align:center;
    width:94%;
  }
`;

export const UserRankBadge = styled.div`
  // position: fixed;
  // bottom: 0px;
  // left: 50%;
  // transform: translateX(-50%);
  // width: 640px;
  // border-radius: 10px;
  // border-top: 2px solid silver;
  // box-shadow: 0px 4px 10px rgba(0, 0, 0, 5.2); /* Added box-shadow */

  // @media (max-width: ${breakpoints.xs}px) {
  //   border-radius: 5px;
  //   width: 95%;
  //   max-width: 350px;
  //   bottom: 78px;
  // }
`;


export const TopThreeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 100%;
  margin-top: -30px;
  @media (max-width: ${breakpoints.xs}px) {
    margin-top: -8px;
    margin-bottom: 15px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: -8px;
    margin-bottom: 15px;
  }
    > *{
  max-width: 100px;}
`;

export const LeadershipProfileWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  &.profile-first {
    order: 2;
    transform: translateY(0px);
  }

  &.profile-second {
    order: 1;
    transform: translateY(21px);
  }

  &.profile-third {
    order: 3;
    transform: translateY(21px);
  }

  // padding: 10px;

  @media (max-width: ${breakpoints.xs}px) {
    &.profile-first {
      order: 2;
      transform: translateY(0px);
    }

    &.profile-second {
      order: 1;
      transform: translateY(20px);
    }

    &.profile-third {
      order: 3;
      transform: translateY(30px);
    }
    // padding: 4px;
  }
`;
