import StaticCard from 'components/UI/Cards/StaticCard'
import TabMenu from 'components/UI/TabMenu';
import { TabMenuItem, TabOptionsLeaderboard } from 'models';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, ContentContainer, HeaderContainer, IconContainer, Img, ImgContainer, InnerButtonDetails, RightText, SettingsContainer, TabMenuContainer, UserContainer, UserDetails } from './styles';
import Button from 'components/UI/Button';
import Icon, { IconType } from 'components/UI/Icon';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import { useAppSelector } from 'hooks/redux';
import { selectUser } from 'store/userState/userState';
import * as routes from 'router/routes';
import { useNavigate } from 'react-router-dom';
import { useGetMembershipSettingsQuery, useGetTotalActivityAllCompaniesQuery, useGetTotalActivityMyCompanyQuery } from 'store';
import TopListSettings from '../TopListSettings';
import TopListSettingsAllCompanies from '../TopListSettingsAllCompanies';
import { dateStringToISOString, getFirstDateOfMonth } from 'utils/date';
import { sortAndRankProfiles } from 'utils/activity';

const TopListOverview = () => {
  const [activeTab, setActiveTab] = useState<number | string>(TabOptionsLeaderboard.myCompany);
  const [activeTabIsMyCompany, setActiveTabIsMyCompany] = useState(activeTab === TabOptionsLeaderboard.myCompany);

  useEffect(() => {
    setActiveTabIsMyCompany(activeTab === TabOptionsLeaderboard.myCompany);
  }, [activeTab]);

  // Hooks
  const intl = useIntl();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const [profileImage] = useGetAssetImage(user?.profilePictureAssetId);

  const settings = useGetMembershipSettingsQuery();
  registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date()));

  const { data: myCompanyUserList } = useGetTotalActivityMyCompanyQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  const { data: allCompaniesUserList } = useGetTotalActivityAllCompaniesQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  // Callbacks
  const onShowMoreClick = useCallback(
    () => {
      const state = { tab: activeTab };
      navigate(routes.PROGRESS_LEADERBOARD, { state });
    },
    [activeTab, navigate]
  );

  interface TopListData {
    userActivity: Array<{ userId: string }>;
    qtyUsers: number;
  }
  const getTopPercentageBracket = (position: number, totalUsers: number): number => {
    if (totalUsers <= 1) return 1;
    if (position === 1) return 1;
    const exactPercentage = (position / totalUsers) * 100;
    return exactPercentage < 1 ? 1 : Math.round(exactPercentage);
  };

  const myCompanyActivityData = useMemo(() =>
    sortAndRankProfiles(myCompanyUserList?.userActivity, user?.id ?? '', true),
    [myCompanyUserList?.userActivity, user?.id]
  );

  const allCompaniesActivityData = useMemo(() =>
    sortAndRankProfiles(allCompaniesUserList?.userActivity, user?.id ?? '', true),
    [allCompaniesUserList?.userActivity, user?.id]
  );
  const [myPercentageTopList, setMyPercentageTopList] = useState<number | string>('-');
  const [myRank, setMyRank] = useState<number | string>(0);

  useEffect(() => {
    const companyUserList = activeTab === TabOptionsLeaderboard.myCompany ? myCompanyUserList : allCompaniesUserList;
    const position = activeTab === TabOptionsLeaderboard.myCompany ? myCompanyActivityData.userRank : allCompaniesActivityData.userRank;
    if (typeof position === 'number' && position <= 0) {
      setMyPercentageTopList('-');
      setMyRank('-');
    } else {
      const totalUsers = companyUserList?.qtyUsers ?? 0;
      const numericPosition = typeof position === 'number' ? position : 0;
      const percentage = getTopPercentageBracket(numericPosition, totalUsers);
      setMyPercentageTopList(percentage);
      setMyRank(numericPosition);
    }
  }, [activeTab, myCompanyUserList, allCompaniesUserList, myCompanyActivityData.userRank, allCompaniesActivityData.userRank]);


  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: TabOptionsLeaderboard.myCompany,
        text: intl.formatMessage({
          id: 'myCompanyButton',
        }),
        backgroundColor: 'blue',
        color: 'white',
        activeMargin: 0.2,
      },
      {
        id: TabOptionsLeaderboard.allCompanies,
        text: intl.formatMessage({
          id: 'allCompaniesButton',
        }),
        backgroundColor: 'blue',
        color: 'white',
        activeMargin: 0.2,
      },
    ];
  }, [intl]);

  const showInToplist = useMemo(() => {
    if (activeTabIsMyCompany && !settings.data?.showInToplistMyCompany) {
      return (
        <TopListSettings />
      )
    } else if (activeTab === TabOptionsLeaderboard.allCompanies && settings.data?.showInToplistAllCompanies === false) {
      return (
        <TopListSettingsAllCompanies />
      )
    } else {

      return (
        <ContentContainer>
          <UserContainer>
            <ImgContainer>
              {profileImage ?
                (
                  <Img src={profileImage} alt={'hello'} />
                )
                : (
                  <Icon type={IconType.User} color="grayText" size={50} />
                )}
            </ImgContainer>
            <UserDetails>
              <b>{user?.firstName} {user?.lastName}</b>
              <span>
                {intl.formatMessage({ id: 'topListRank' })}
                {' '}
                {myRank}
              </span>
            </UserDetails>
          </UserContainer>
          <RightText>
            <Icon type={IconType.BarChart2} color="grayText" />
            <h2>Top {myPercentageTopList}%</h2>
          </RightText>
        </ContentContainer>
      )
    }
  },
    [activeTabIsMyCompany, settings.data?.showInToplistMyCompany, settings.data?.showInToplistAllCompanies, activeTab, profileImage, user?.firstName, user?.lastName, intl, myRank, myPercentageTopList]);

  return (
    <StaticCard>
      <Container>
        <HeaderContainer>
          <TabMenuContainer>
            <TabMenu
              menuId="toplist-overview"
              backgroundColor='backgroundDark'
              menu={tabMenu}
              activeTab={activeTab}
              setActiveTab={setActiveTab} />
          </TabMenuContainer>
          <Button background='transparent' color='black' onClick={() => onShowMoreClick()}>
            <InnerButtonDetails>
              {' '}
              <FormattedMessage id="competitionTopListShowMore" />
              <IconContainer>
                <Icon type={IconType.Arrow} color="grayText" />
              </IconContainer>
            </InnerButtonDetails>
          </Button>
        </HeaderContainer>

        <SettingsContainer>
          {showInToplist}
        </SettingsContainer>
      </Container>
    </StaticCard >
  )
}


export default TopListOverview