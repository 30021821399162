import styled from "styled-components";
import { breakpoints } from "styles";


export const HeadingTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
`;

export const ContentSectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CustomRichTextContainer = styled.div`
  margin: 1.5em 0px;
  padding: 0px;

  @media (min-width: ${breakpoints.m}px) {
  padding: 0 0 0 1.5rem;
  }
`;

export const Img = styled.img`
  position: relative;
  z-index: 1;
  pointer-events: none;
  object-fit: contain;
  max-width: 230px;
  max-height: 100%;
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-height: 250px;
  width: 100%;
`;

export const ContentTextContainer = styled.div`

  // @media (min-width: ${breakpoints.l}px) {
  // padding: 0 0 0 1.5rem;
  // }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-height: 960px;
  `;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 0em 1.25em 1.25em 1.25em;
  font-size: 1.1rem;
  flex-direction: column;
  justify-content: space-between;

`;


export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 0;
  margin-top: 16px;
`;


export const ButtonText = styled.div`
  text-decoration: underline;
`;