import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { getDateTime } from 'utils/date';
import { ActionContainer } from 'styles';

import Button from 'components/UI/Button/Button';
import TextField from 'components/UI/TextField/TextField';
import SelectField from 'components/UI/SelectField';

import { Fields, Form } from './styles';
import { SelectItem } from '../ChallengeForm/styles';
import RadioButton from 'components/UI/RadioButton';
import { useIntervalOptions } from 'hooks/useInterval';
import { NotificationSchedulePersonalIn } from 'models/notificationSchedule/notificationSchedule';


type Props = {
  submitForm: (data: NotificationSchedulePersonalIn) => void;
  isOpen: boolean;
  slug?: string | null;
  redirectUrl?: string;
};

const RegisterContentForm: FC<Props> = ({ submitForm, isOpen, slug, redirectUrl }) => {
  const intl = useIntl();
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('All');
  const [startDateState, setStartDateState] = useState<string>(getDateTime());

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start: startDateState,
      stop: getDateTime(),
      repeatInterval: 'None',
      contentSlug: slug,
      preferredNotificationMethod: selectedNotifyOption,
      redirectLink: redirectUrl,
    },
  });

  const intervalOption = watch('repeatInterval');

  useEffect(() => {
    setValue('contentSlug', slug);
    setValue('preferredNotificationMethod', selectedNotifyOption);
    setValue('redirectLink', redirectUrl);
  }, [redirectUrl, selectedNotifyOption, setValue, slug]);

  useEffect(() => {
    const stopDate = new Date(startDateState);
    const startDate = new Date(startDateState);
    startDate.setMinutes(stopDate.getMinutes() + 5);

    setValue('start', startDateState);

    if (intervalOption === 'Daily') {
      const stopDate = new Date(startDate);
      stopDate.setDate(startDate.getDate() + 7);

      setValue('stop', getDateTime(stopDate));
      setValue('start', getDateTime(startDate));
    } else if (intervalOption === 'Weekly') {
      startDate.setDate(startDate.getDate() + 7);
      stopDate.setDate(startDate.getDate() + 4 * 7);

      const currentDate = new Date();
      startDate.setHours(currentDate.getHours() - 2);

      setValue('start', getDateTime(startDate));
      setValue('stop', getDateTime(stopDate));
    } else if (intervalOption === 'Monthly') {

      startDate.setDate(startDate.getDate() + 4 * 7);
      stopDate.setMonth(startDate.getMonth() + 3);

      const currentDate = new Date();
      startDate.setHours(currentDate.getHours() - 2);

      setValue('start', getDateTime(startDate));
      setValue('stop', getDateTime(stopDate));
    } else {

      setValue('start', getDateTime(startDate));
    }
  }, [intervalOption, setValue, startDateState]);


  // Hooks
  const intervalOptions = useIntervalOptions();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const {
        start,
        stop,
        repeatInterval,
        contentSlug,
        preferredNotificationMethod,
        redirectLink,
      } = values;

      const toUTCString = (localDatetime: string | number | Date) => {
        const date = new Date(localDatetime);
        return date.toISOString();
      };

      const startUTC = toUTCString(start);
      let stopUTC = toUTCString(stop);

      if (repeatInterval === 'None') {
        const startDate = new Date(start);
        const stopDate = new Date(startDate);
        stopDate.setDate(startDate.getDate() + 1);
        stopUTC = toUTCString(stopDate);
      }

      submitForm({
        start: startUTC,
        stop: stopUTC,
        repeatInterval,
        contentSlug,
        preferredNotificationMethod,
        redirectLink,
      });
    },
    [submitForm]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <TextField
          type="datetime-local"
          label={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input'
          })}
          placeholder={intl.formatMessage({
            id: 'inputStartDateLabel',
            defaultMessage: 'Start date',
            description: 'Label for start date input'
          })}
          error={errors.start}
          register={register('start', {
            required: intl.formatMessage({
              id: 'inputErrorFieldRequired',
              defaultMessage: 'Field is is required',
              description: 'Input error for field required',
            }),
          })}
        />
        {intervalOption !== 'None' &&
          <TextField
            type="datetime-local"
            label={intl.formatMessage({
              id: 'inputStopDateLabel',
              defaultMessage: 'Stop date',
              description: 'Label for stop date input',
            })}
            placeholder={intl.formatMessage({
              id: 'inputStopDateLabel',
              defaultMessage: 'Stop date',
              description: 'Label for stop date input',
            })}
            error={errors.stop}
            register={register('stop', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
          />
        }
        <Fields>
          <SelectField
            label={intl.formatMessage({
              id: 'NotificationSendingOptionsLabel',
              defaultMessage: 'Dispatch options',
              description: 'Unit text for activities',
            })}
            register={register('repeatInterval', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is is required',
                description: 'Input error for field required',
              }),
            })}
            options={intervalOptions}
            error={errors.contentSlug}
          />
        </Fields>
        <SelectItem onClick={() => setSelectedNotifyOption('All')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'All'} />
          <span>
            <FormattedMessage
              id="NotificationAppAndEmailDispatchOption"
              defaultMessage="Wellr notis och email"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
        <SelectItem onClick={() => setSelectedNotifyOption('Phone')}>
          <RadioButton name="custom-title" isChecked={selectedNotifyOption === 'Phone'} />
          <span>
            <FormattedMessage
              id="NotificationOnlyAppDispatchOption"
              defaultMessage="Wellr notis"
              description="Radio button label enabling/disabling custom competition title"
            />
          </span>
        </SelectItem>
      </div>
      <ActionContainer>
        <Button type="submit" background="blue">
          <FormattedMessage
            id="registerReminderButton"
            defaultMessage="Register health focus"
            description="Register health focus modal title"
          />
        </Button>
      </ActionContainer>
    </Form>
  );
};

export default RegisterContentForm;
