import {
  CompanyCommunication,
  CompanyDetails,
  CompanyMembershipInfo,
  CompanyMembershipValues,
  CompanyQuery,
  CompanyQuiz,
  CompanyReport,
  CompanyValue,
  CompanyPulseQuiz,
  CompanyPulseQuizListItem,
  CompanyPulseQuizListQuery,
  CompanyPulseQuizQuery,
  CompanyHealthQuiz,
  CompanyHealthQuizQuery,
} from 'models';
import { ExtractApiResponseType } from 'utils/api';
import { baseApi } from '../baseApi';
import {
  AddCompanyContentType,
  CompanyHealthQuizStatsType,
  DeleteCompanyContentType,
  endpoints,
  GetCompanyContentType,
} from './endpointDefinitions';
import { components } from 'generated/api';

const companyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyMe: builder.query<
      ExtractApiResponseType<'/api/company/me', 'get'>,
      void
    >({
      query: () => '/company/me',
      providesTags: ['Company'],
    }),
    getCompanyReport: builder.query<CompanyReport, { companyId?: string }>({
      query: ({ companyId }) => ({
        url: 'company/me/report',
        params: { companyId },
      }),
      providesTags: ['Company'],
    }),
    getCompanyQuizStatistics: builder.query<CompanyQuiz, CompanyQuery>({
      query: ({ employeeType, language, companyId }) => ({
        url: `company/me/quiz/${employeeType}`,
        params: { companyId, language },
      }),
      providesTags: ['Company'],
    }),
    getCompanyPulseQuizList: builder.query<
      CompanyPulseQuizListItem[],
      CompanyPulseQuizListQuery
    >({
      query: ({ language, companyId }) => ({
        url: `/pulsequiz`,
        params: { language, companyId },
      }),
      providesTags: ['PulseQuiz'],
    }),
    getCompanyPulseQuiz: builder.query<CompanyPulseQuiz, CompanyPulseQuizQuery>(
      {
        query: ({ id, language, companyId }) => ({
          url: `/pulsequiz/${id}`,
          params: { id, language, companyId },
        }),
        providesTags: ['PulseQuiz'],
      }
    ),
    getCompanyHealthQuiz: builder.query<
      CompanyHealthQuizStatsType['result'],
      CompanyHealthQuizStatsType['queryArgs']
    >({
      query: ({ quizSlug, language, date, companyId }) => ({
        url: date
          ? '/company/me/quiz/from-date'
          : `/company/me/quiz/latest/${quizSlug}`,
        params: date
          ? { quizSlug, language, date, companyId }
          : { language, companyId },
      }),
      providesTags: ['Quiz'],
    }),
    getCompanyHealthQuizOld: builder.query<
      CompanyHealthQuiz,
      CompanyHealthQuizQuery
    >({
      query: ({ quizSlug, language, fromDate, companyId }) => ({
        url: fromDate
          ? '/company/me/quiz/from-date'
          : `/company/me/quiz/latest/${quizSlug}`,
        params: fromDate
          ? { quizSlug, language, date: fromDate, companyId }
          : { language, companyId },
      }),
      providesTags: ['Quiz'],
    }),
    getCompanyCommunication: builder.query<CompanyCommunication, CompanyQuery>({
      query: ({ language, companyId }) => ({
        url: `/company-communication`,
        params: { language, companyId },
      }),
      providesTags: ['CompanyCommunication'],
    }),
    addCompanyContent: builder.mutation<
      AddCompanyContentType['result'],
      {
        companyId: string;
        slug: string;
        languages?: components['schemas']['Languages'];
      }
    >({
      query: ({ companyId, slug, languages }) => ({
        url: endpoints.companyContent.addCompanyContent.path(companyId, slug),
        method: endpoints.companyContent.addCompanyContent.method,
        body: languages,
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyContent', id: companyId },
      ],
    }),
    getCompanyContent: builder.query<
      GetCompanyContentType['result'],
      GetCompanyContentType['queryArgs']
    >({
      query: ({ companyId }) => ({
        url: endpoints.companyContent.getCompanyContent.path(companyId),
        method: endpoints.companyContent.getCompanyContent.method,
      }),
      providesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyContent', id: companyId },
      ],
    }),
    deleteCompanyContent: builder.mutation<
      DeleteCompanyContentType['result'],
      DeleteCompanyContentType['queryArgs']
    >({
      query: ({ companyId, slug }) => ({
        url: endpoints.companyContent.deleteCompanyContent.path(
          companyId,
          slug
        ),
        method: endpoints.companyContent.deleteCompanyContent.method,
      }),
      invalidatesTags: (_result, _error, { companyId }) => [
        { type: 'CompanyContent', id: companyId },
      ],
    }),
    getCompany: builder.query<CompanyDetails, CompanyValue>({
      query: ({ companyId }) => `/company/company/${companyId}`,
      providesTags: ['Company'],
    }),
    getCompanyMembershipInfo: builder.query<
      CompanyMembershipInfo,
      CompanyMembershipValues
    >({
      query: ({ companyDescription, companyId }) => ({
        url: `/company/company/membership-info/${companyDescription}`,
        params: { companyId },
      }),
    }),
    getCompanyQuizDates: builder.query<
      ExtractApiResponseType<'/api/company/me/quiz/dates', 'get'>,
      { companyId?: string }
    >({
      query: ({ companyId }) => ({
        url: `company/me/quiz/dates/`,
        params: { companyId },
      }),
    }),
  }),
});

export const {
  useGetCompanyMeQuery,
  useGetCompanyReportQuery,
  useGetCompanyQuizStatisticsQuery,
  useGetCompanyCommunicationQuery,
  useAddCompanyContentMutation,
  useGetCompanyContentQuery,
  useDeleteCompanyContentMutation,
  useGetCompanyQuery,
  useGetCompanyMembershipInfoQuery,
  useGetCompanyPulseQuizListQuery,
  useGetCompanyPulseQuizQuery,
  useGetCompanyHealthQuizQuery,
  useLazyGetCompanyHealthQuizQuery,
  useGetCompanyQuizDatesQuery,
} = companyApi;
