import { FC, useEffect, useState } from 'react';
import { ThemeColor } from 'styles';
import { Slider, TextGrid, TextCol } from './styles';

type Props = {
  color?: ThemeColor;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;
  minText?: string;
  maxText?: string;
  minDescription?: string;
  onChange?: (value: number) => void;
  disabled?: boolean;
  reversed?: boolean;
};

const SliderField: FC<Props> = ({
  onChange,
  minValue,
  maxValue,
  defaultValue,
  minText,
  maxText,
  color = 'white',
  disabled = false,
  reversed = true,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (value: number | readonly number[], index: number) => {
    let newValue = Array.isArray(value) ? value[0] : value;
    let transformedValue = maxValue !== undefined ? maxValue - newValue : newValue;
    if (minValue !== undefined) {
      transformedValue = Math.max(minValue, transformedValue);
    }
    setValue(transformedValue);
    onChange?.(transformedValue);
  };

  let displayValue = maxValue !== undefined && reversed !== undefined && value !== undefined ? maxValue - value : value;

  return (
    <div>
      <Slider
        className={disabled ? 'disabled' : ''}
        reversed={reversed}
        color={color}
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        onAfterChange={handleChange}
        min={minValue}
        max={maxValue}
        value={displayValue === 99 ? 100 : displayValue}
        disabled={disabled}
      />
      {minText || maxText ? (
        <TextGrid>
          {reversed ? (
            <>
              <TextCol>{maxText}</TextCol>
              <TextCol alignRight>{minText}</TextCol>
            </>
          ) : (
            <>
              <TextCol>{minText}</TextCol>
              <TextCol alignRight>{maxText}</TextCol>
            </>
          )}
        </TextGrid>
      ) : null}
    </div>
  );
};

export default SliderField;