import { ReactNode } from 'react';
import { ThemeColor } from 'styles';
import { IconType } from 'components/UI/Icon';
import { ContentText, ReferenceType } from 'models/content/content';

export enum MenuType {
  Regular = '1',
  CompanyAdmin = '2',
  WellrAdmin = '3',
}

export type MenuItem = {
  id: string | number;
  text: ReactNode;
  icon?: IconType;
  onClick?: () => void;
  link?: string;
};

export type NotificationableMenuItem = MenuItem & { hasNotification: boolean };

export interface DropdownMenuItem extends MenuItem {
  color?: ThemeColor;
}

export interface TabMenuItem extends MenuItem {
  color?: ThemeColor;
  backgroundColor?: ThemeColor;
  hasNotification?: boolean;
  activeMargin?: number;
}

export type ActiveFilters = {
  id: string;
  filters: string[];
};

export type LayoutState = {
  pageTitle: string;
  inverted: boolean;
  icon: IconType | null;
  link: string | null;
  reminder: Reminder | null;
  favorite: FavoriteBody | null;
  activeExpandable: string | null;
  activeFilters: ActiveFilters | null;
  companyInHeader: boolean;
  activeMenuTab: MenuType;
};

export type Reminder = {
  slug: string;
  redirectUrl: string;
}

export type PageHeader = {
  title: string;
  inverted?: boolean;
  icon?: IconType;
  link?: string;
  favorite?: FavoriteBody;
  reminder?: Reminder;
  companyInHeader?: boolean;
};

export type ProgressItem = {
  text: string;
  value: number;
  color: ThemeColor;
};

export type Favorite = {
  id: string;
  type: ReferenceType;
  reference: string;
  title: ContentText;
  typeTitle: ContentText;
};

export type FavoriteBody = {
  type: ReferenceType;
  reference: string;
};

export type FavoriteQuery = {
  language?: string;
  type?: ReferenceType;
  reference?: string;
};

export type FavoriteExists = {
  exists: boolean;
};
