import { FC, useEffect, useMemo } from 'react';
import Loader from 'components/UI/Loader';
import IconButton from 'components/UI/IconButton';
import SlideoutModal from 'components/UI/SlideoutModal';
import Heading, { Tag } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-hot-toast';
import { Header, Body, Fields, SelectItem } from './styles';
import { useForm } from 'react-hook-form';
import {
  useAddCompanyContentMutation,
  localizationSelector,
  useGetArticleCategoryListQuery,
  useGetArticleCategoryQuery,
  useGetExerciseCategoryListQuery,
  useGetExerciseProgramCategoryListQuery,
  useGetExerciseProgramCategoryQuery,
  useGetExerciseCategoryQuery,
} from 'store';
import Button from 'components/UI/Button';
import { FlexContainer } from 'styles';
import SelectField from 'components/UI/SelectField';
import { useAppSelector } from 'hooks/redux';
import { SelectOption } from 'models';
import TextField from 'components/UI/TextField';
import RadioButton from 'components/UI/RadioButton';
import { components } from 'generated/api';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyId: string;
};

/// Used to add wellr content to a company overview
/// Content could be articles, exercises etc
const AddCompanyOverviewContentModal: FC<Props> = ({
  isOpen,
  onClose,
  companyId,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    contentSlug: string;
    categorySlug: string;
    contentCategory: string;
    customTitle: boolean;
    title: components['schemas']['Languages'];
  }>();

  // Watch
  const [
    selectedCategorySlug,
    customTitle,
    contentSlug,
    selectedContentType
  ] = watch(
    [
      'contentCategory',
      'customTitle',
      'contentSlug',
      'categorySlug'
    ]);


  // Hooks
  const intl = useIntl();
  const { language, languages } = useAppSelector(localizationSelector);

  const [addCompanyContent, addCompanyContentResult] =
    useAddCompanyContentMutation();

  const enum ContentTypes {
    Article = 'article',
    Exercise = 'exercise',
    Program = 'program',
  }

  const contentTypes = [
    { value: ContentTypes.Article, name: 'Artiklar' },
    { value: ContentTypes.Exercise, name: 'Övningar' },
    { value: ContentTypes.Program, name: 'Program' },
  ];

  // Hooks Articles
  const { data: categoryData } = useGetArticleCategoryListQuery({
    language: language?.languageCode,
  });
  const { data: articlesInCategory } = useGetArticleCategoryQuery({
    language: language?.languageCode,
    slug: selectedCategorySlug,
  });

  const { data: exercisesCategoryData } = useGetExerciseCategoryListQuery({
    language: language?.languageCode,
  });
  const { data: exercisesData } = useGetExerciseCategoryQuery({
    language: language?.languageCode,
    slug: selectedCategorySlug,
  });

  const { data: exerciseProgramCategoryData } =
    useGetExerciseProgramCategoryListQuery({
      language: language?.languageCode,
    });
  const { data: exerciseProgramsData } = useGetExerciseProgramCategoryQuery({
    language: language?.languageCode,
    slug: selectedCategorySlug,
  });

  // Rename articleOptions to contentOptions since it will handle all content types
  const dynContentOptions: SelectOption[] = useMemo(() => {
    if (!selectedCategorySlug) return [];

    switch (selectedContentType) {
      case ContentTypes.Article:
        if (articlesInCategory) {
          return articlesInCategory.articles.map((item) => ({
            value: item.slug.current,
            name: item.title[language?.languageCode ?? 'sv'],
          }));
        }
        break;

      case ContentTypes.Program:
        if (exerciseProgramsData) {
          return exerciseProgramsData.exercisePrograms.map((item) => ({
            value: item.slug.current,
            name: item.title ? item.title[language?.languageCode ?? 'sv'] : '',
          }));
        }
        break;

      case ContentTypes.Exercise:
        if (exercisesData) {
          return exercisesData.exercises.map((item) => ({
            value: item.slug.current,
            name: item.title ? item.title[language?.languageCode ?? 'sv'] : '',
          }));
        }
        break;
    }
    return [];
  }, [
    selectedContentType,
    selectedCategorySlug,
    articlesInCategory,
    exerciseProgramsData,
    language?.languageCode,
    exercisesData
  ]);

  // Get category options based on selected content type
  const categoryOptions: SelectOption[] = useMemo(() => {
    switch (selectedContentType) {
      case ContentTypes.Article:
        return (
          categoryData
            ?.slice()
            .sort((a, b) =>
              a.title![language?.languageCode ?? 'sv'].localeCompare(
                b.title![language?.languageCode ?? 'sv']
              )
            )
            .map((item) => ({
              value: item.slug.current,
              name: item.title![language?.languageCode ?? 'sv'],
            })) ?? []
        );
      case ContentTypes.Exercise:
        return (
          exercisesCategoryData
            ?.slice()
            .sort((a, b) =>
              a.title![language?.languageCode ?? 'sv'].localeCompare(
                b.title![language?.languageCode ?? 'sv']
              )
            )
            .map((item) => ({
              value: item.slug.current,
              name: item.title![language?.languageCode ?? 'sv'],
            })) ?? []
        );
      case ContentTypes.Program:
        return (
          exerciseProgramCategoryData
            ?.slice()
            .sort((a, b) =>
              (a.title?.[language?.languageCode ?? 'sv'] ?? '').localeCompare(
                b.title?.[language?.languageCode ?? 'sv'] ?? ''
              )
            )
            .map((item) => ({
              value: item.slug.current,
              name: item.title?.[language?.languageCode ?? 'sv'] ?? '',
            })) ?? []
        );
      default:
        return [];
    }
  }, [
    selectedContentType,
    categoryData,
    exercisesCategoryData,
    exerciseProgramCategoryData,
    language?.languageCode,
  ]);

  const customTitleInput = useMemo(() => {
    if (!customTitle) {
      return null;
    }
    return (
      <>
        <br />
        {languages.map((language, index) => (
          <TextField
            key={index}
            label={
              index === 0
                ? intl.formatMessage({
                  id: 'inputCompanyContentStartPageLabel',
                })
                : undefined
            }
            placeholder={intl.formatMessage({
              id: 'inputCompanyContentStartPagePlaceholder',
            })}
            type="text"
            error={errors.title}
            fieldCountryCode={language.countryCode}
            register={register(`title.${language.languageCode}`)}
          />
        ))}
      </>
    );
  }, [customTitle, languages, intl, errors, register]);

  useEffect(() => {
    if (addCompanyContentResult.isSuccess) {
      toast.success(
        intl.formatMessage({
          id: 'updateCompanySuccess',
        })
      );
    } else if (addCompanyContentResult.isError) {
      toast.error(
        intl.formatMessage({
          id: 'updateCompanyError',
        })
      );
    }
  }, [addCompanyContentResult, intl]);

  // Auto-select first category when content type is selected
  useEffect(() => {
    if (selectedContentType && categoryOptions.length > 0) {
      setValue('contentCategory', String(categoryOptions[0].value));
    }
  }, [selectedContentType, categoryOptions, setValue]);

  // Auto-select first content item when category is selected
  useEffect(() => {
    if (selectedCategorySlug && dynContentOptions.length > 0) {
      setValue('contentSlug', String(dynContentOptions[0].value));
    }
  }, [selectedCategorySlug, dynContentOptions, setValue]);

  // Content
  const content = useMemo(() => {
    if (addCompanyContentResult.isLoading) {
      return <Loader padding color="blue" />;
    }
    return (
      <form
        onSubmit={handleSubmit(async (formData) => {
          await addCompanyContent({
            slug: formData.contentSlug,
            companyId: companyId,
            languages: customTitle ? formData.title : undefined,
          }).unwrap();
          onClose();
        })}
      >
        <Fields>
          <SelectField
            register={register('categorySlug', {
              required: intl.formatMessage({
                id: 'inputErrorFieldRequired',
                defaultMessage: 'Field is required',
                description: 'Input error for field required',
              }),
            })}
            label={intl.formatMessage({
              id: 'inputContentTypeLabel',
              defaultMessage: 'Content Type',
              description: 'Label for content type input',
            })}
            options={contentTypes}
            defaultValue={''}
            error={errors.categorySlug}
          />

          {selectedContentType && (
            <SelectField
              register={register('contentCategory', {
                required: intl.formatMessage({
                  id: 'inputErrorFieldRequired',
                  defaultMessage: 'Field is required',
                  description: 'Input error for field required',
                }),
              })}
              label={intl.formatMessage({
                id: 'inputCategoryLabel',
                defaultMessage: 'Category',
                description: 'Label for category input',
              })}
              options={categoryOptions}
              error={errors.contentCategory}
            />
          )}

          {selectedCategorySlug && (
            <SelectField
              label={intl.formatMessage({
                id: 'inputContentLabel',
                defaultMessage: 'Content',
                description: 'Label for content input',
              })}
              register={register('contentSlug', {
                required: intl.formatMessage({
                  id: 'inputErrorFieldRequired',
                  defaultMessage: 'Field is required',
                  description: 'Input error for field required',
                }),
              })}
              options={dynContentOptions}
              error={errors.contentSlug}
            />
          )}

          <SelectItem onClick={() => setValue('customTitle', !customTitle)}>
            <RadioButton name="custom-title" isChecked={customTitle} />
            <span>
              <FormattedMessage
                id="inputCompanyContentRadioButtonLabelCustomTitle"
              />
            </span>
          </SelectItem>
        </Fields>
        {customTitleInput}
        <FlexContainer>
          <Button type="submit" background="blue">
            <FormattedMessage
              id="saveButton"
              defaultMessage="Save"
              description="Save button text"
            />
          </Button>
        </FlexContainer>
      </form>
    );
  }, [
    addCompanyContentResult.isLoading,
    handleSubmit,
    register,
    intl,
    dynContentOptions,
    categoryOptions,
    selectedContentType,
    selectedCategorySlug,
    errors,
    customTitle,
    setValue,
    companyId
  ]);

  const onModalClose = () => {
    onClose();
  };

  return (
    <SlideoutModal isOpen={isOpen} onClose={onModalClose}>
      <div>
        <Header>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="companyContentTitle"
              defaultMessage="Lägg till innehåll till startsidan"
              description="Modal title for adding company communication"
            />
          </Heading>
          <IconButton onClick={onClose} padding>
            <Icon type={IconType.Close} />
          </IconButton>
        </Header>
        <Body>{content}</Body>
      </div>
    </SlideoutModal>
  );
};

export default AddCompanyOverviewContentModal;
