import { Activity } from 'models';
import { IntlShape } from 'react-intl';

// Group array of activities by timestamp and remove duplicates
export function groupActivities(activities: Activity[]) {
  const grouped: Activity[] = [];
  activities.forEach((activity) => {
    const found = grouped.find((item) => item.timestamp === activity.timestamp);
    if (!found) {
      grouped.push(activity);
    }
  });
  return grouped;
}

export function getStepPercentage(total: number, left: number): number {
  const value = total - left;
  return (value * 100) / total;
}

export const getUnitTranslation = (
  unitSlug: 'step' | 'minute' | string,
  unitCount: number,
  intl: IntlShape
) => {
  switch (unitSlug) {
    case 'step':
      return intl.formatMessage(
        {
          id: 'numberSteps',
          defaultMessage: '{unitCount, plural, one {# step} other {# steps}}',
          description: 'Number of step(s)',
        },
        { unitCount }
      );
    case 'minute':
      return intl.formatMessage(
        {
          id: 'numberMinutes',
          defaultMessage:
            '{unitCount, plural, one {# minute} other {# minutes}}',
          description: 'Number of minute(s)',
        },
        { unitCount }
      );
    default:
      console.warn('No translation for unit slug found', unitSlug);
      return `${unitCount} ${unitSlug}`;
  }
};

interface UserActivity {
  userId: string;
  steps: number;
  points: number;
}

export const sortAndRankProfiles = (
  userActivity: UserActivity[] | undefined,
  userId: string,
  isWellrSteps: boolean
) => {
  if (!userActivity?.length) {
    return {
      topProfiles: [],
      remainingProfiles: [],
      userRank: '-',
      userProfile: undefined
    };
  }

  const sortedActivity = [...userActivity]
    .sort((a, b) => isWellrSteps ? b.steps - a.steps : b.points - a.points)
    .map((profile, index) => ({
      ...profile,
      rank: (index + 1).toString()
    }));

  return {
    topProfiles: sortedActivity.slice(0, 10),
    remainingProfiles: sortedActivity.slice(10),
    userRank: sortedActivity.findIndex(
      (activity) => activity.userId === userId
    ) + 1,
    userProfile: sortedActivity.find(
      (profile) => profile.userId === userId
    )
  };
};
