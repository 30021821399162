import { FC, useCallback, useEffect } from 'react'
import { ButtonContainer, Container, ContentContainer, ContentTextContainer, CustomRichTextContainer, HeadingTitle, Img, ImgContainer } from './styles'
import { localizationSelector, useContentSearchBySlugQuery, useGetCompanyCommunicationQuery } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppSelector } from 'hooks/redux';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Button from 'components/UI/Button';
import { usePretendCompany } from 'hooks/usePretendCompany';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import RichText from 'components/fragments/RichText';
import { ContentWidth } from 'styles';
import { selectUser } from 'store/userState/userState';
import { companiesSpecificOnboardingStep } from 'constants/onboardingGuids';

const CompanyOnboardingStep: FC = () => {
  const user = useAppSelector(selectUser);
  const companySpecific = companiesSpecificOnboardingStep.find((company) => user?.membership.companyId === company.Id);

  // Hooks
  const { data } = useContentSearchBySlugQuery({ slug: companySpecific?.Slug ?? '' });
  const { language } = useAppSelector(localizationSelector);
  const getText = useLocalizedText();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companySpecific) {
      navigate('/overview');
    }
  }, [companySpecific, navigate]);

  const companyId = usePretendCompany();

  const { companyLogoAssetId, companyLogoLoaded } =
    useGetCompanyCommunicationQuery(
      {
        language: language?.languageCode,
        companyId,
      },
      {
        selectFromResult: (response) => ({
          companyLogoLoaded: response.isSuccess || response.isError,
          companyLogoAssetId:
            response?.data?.company?.logoAssetId ??
            response?.data?.umbrellaCompany?.logoAssetId,
        }),
      }
    );

  const [companyLogoSrc] = useGetAssetImage(companyLogoAssetId);

  const handleNext = useCallback(() => {
    navigate(`/welcome/step1`);
  }, [navigate]);

  if (!companySpecific) {
    return null;
  }

  if (!data?.length || !data[0]?.content) {
    return null;
  }

  const { content, title } = data[0];

  return (
    <ContentWidth noMargins>
      <Container>
        <ImgContainer>
          {companyLogoLoaded && companyLogoSrc &&
            <Img src={companyLogoSrc} alt="company logo" />
          }
        </ImgContainer>
        <ContentContainer>
          <ContentTextContainer>
            <HeadingTitle>
              {getText(title)}
            </HeadingTitle>
            <CustomRichTextContainer>
              <RichText>
                {content}
              </RichText>
            </CustomRichTextContainer>
          </ContentTextContainer>
          <ButtonContainer>
            <Button
              size="large"
              color="white"
              background={companySpecific.Color}
              border="backgroundDark"
              rounded
              width='100%'
              onClick={() => { handleNext() }}
            >

              <FormattedMessage
                id={'pageCompanyOnboardingStepGetStartedButton'}
                defaultMessage="Kom igång"
                description=""
              />
            </Button>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </ContentWidth>
  )
}

export default CompanyOnboardingStep;