import React from 'react';
import {
  ProfileWrapper,
  RankText,
  ProfileImage,
  NameText,
  CompanyText,
  WellrpointWrapper,
  WellrpointLogo,
  RankIcon,
  WellrpointText,
  WellrstepsWrapper,
  WellrstepsLogo,
  WellrstepsText,
  RankSvgWrapper,
  UserDetailsDesktop,
  UserDetailsMobile, // Import the RankSvgWrapper
} from './styles';

import goldIcon from '../../../assets/RankIcon/gold.svg';
import silverIcon from '../../../assets/RankIcon/silver.svg';
import bronzeIcon from '../../../assets/RankIcon/bronze.svg';
import useIsMobile from 'hooks/useIsMobile';

interface LedarshipProfileProps {
  rank: '1' | '2' | '3' | string;
  profileImage: string;
  profileName: string;
  company: string;
  wellrpoint: string;
  wellrpointLogo: string;
  wellrsteps?: string;
  wellrstepsLogo?: string;
  rankSize?: 'first' | 'second' | 'third' | string;
  noBackground?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  topThree?: boolean;
  isMe: boolean;
}

const LedarshipProfile: React.FC<LedarshipProfileProps> = ({
  rank,
  topThree,
  profileImage,
  profileName,
  company,
  wellrpoint,
  wellrpointLogo,
  wellrsteps = '',
  wellrstepsLogo = '',
  rankSize,
  noBackground = false,
  isFirst,
  isLast,
  isMe
}) => {
  const rankIcons: { [key in '1' | '2' | '3']: string } = {
    '1': goldIcon,
    '2': silverIcon,
    '3': bronzeIcon,
  };

  const isMobile = useIsMobile();

  const isTopRank = ['1', '2', '3'].includes(rank);

  return (
    <ProfileWrapper
      columnLayout={isTopRank}
      noBackground={noBackground}
      isFirst={isFirst}
      isLast={isLast}
      rank={rank}
    >
      <RankText>
        {isTopRank ? (
          <RankSvgWrapper>
            <RankIcon
              src={rankIcons[rank as '1' | '2' | '3']}
              alt={`${rank} icon`}
            />
          </RankSvgWrapper>
        ) : (
          rank
        )}
      </RankText>
      <ProfileImage
        src={profileImage}
        alt={`${profileName}'s profile`}
        rankSize={rankSize}
      />
      {isMobile ?
        <UserDetailsMobile>
          <NameText isMe={isMe}>{profileName}</NameText>
          <CompanyText>{company}</CompanyText>
        </UserDetailsMobile>
        :
        <UserDetailsDesktop direction={topThree ? 'column' : 'row'}>
          <NameText isMe={isMe}>{profileName}</NameText>
          <CompanyText>{company}</CompanyText>
        </UserDetailsDesktop>
      }
      {wellrsteps ? (
        <WellrstepsWrapper>
          <WellrstepsText>{wellrsteps}</WellrstepsText>
          {wellrstepsLogo && (
            <WellrstepsLogo src={wellrstepsLogo} alt="Wellrsteps Logo" />
          )}
        </WellrstepsWrapper>
      ) : (
        <WellrpointWrapper>
          <WellrpointText>{wellrpoint}</WellrpointText>
          <WellrpointLogo src={wellrpointLogo} alt="Wellrpoint Logo" />
        </WellrpointWrapper>
      )}
    </ProfileWrapper>
  );
};

export default LedarshipProfile;
