import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  localizationSelector,
  setLayoutHeader,
  useGetMembershipSettingsQuery,
  useGetUserQuery,
} from 'store';
import { ContentWidth } from 'styles';
import TabMenu from 'components/UI/TabMenu';
import {
  BadgeformatDate,
} from 'utils/date';
import { TabMenuItem } from 'models';
import HorizontalBadge from 'components/fragments/HorizontalBadge';
import BadgeDetails from 'components/fragments/BadgeDetails/BadgeDetails';
import { getImageUrl } from 'constants/urls';
import useAchievementsData from '../../hooks/useAchievementsData';
import * as routes from 'router/routes';

import {
  CardContainer,
  NumberContainer,
  ImageGrid,
  ImageCard,
  TitleRow,
  AllBadgesContainer,
  BackButton,
  BackButtonWrapper,
  Heading,
  BackHeaderButton,
  BackButtonText,
} from './styles';

import { AchievementResponseType } from 'models/achievement/achievement';
import Loader from 'components/UI/Loader';
import { useAppSelector } from 'hooks/redux';
import Icon, { IconType } from 'components/UI/Icon';
import { useNavigate } from 'react-router-dom';
import Leaderboard from './Leaderboard';

interface ProgressProps {
  tab?: 1 | 2;
}


const Progress: React.FC<ProgressProps> = ({ tab }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { language } = useAppSelector(localizationSelector);

  // States
  const [activeTab, setActiveTab] = useState<number | string>(tab ?? 1);
  const [viewAllMode, setViewAllMode] = useState<
    'myBadges' | 'lockedBadges' | 'badgeDetails' | null
  >(null);
  const [parentView, setParentView] = useState<
    'myBadges' | 'lockedBadges' | null
  >(null);

  const tabMenu: TabMenuItem[] = useMemo(
    () => [
      {
        id: 1,
        text: intl.formatMessage({ id: 'pageMyBadgesTitle' }),
        link: routes.PROGRESS
      },
      {
        id: 2,
        text: intl.formatMessage({ id: 'pageMyLeaderboardTitle' }),
        link: routes.PROGRESS_LEADERBOARD
      },
    ],
    [intl]
  );

  {
    // Hooks
    const {
      allLockedBadges,
      allUnlocked,
      loading,
    } = useAchievementsData();


    const backHeaderButton = useMemo(() => {
      if (viewAllMode === null || activeTab === 2) {
        return;
      }
      return (
        <BackHeaderButton
          onClick={() => setViewAllMode(null)}
        >
          <Icon type={IconType.ArrowLeft} color="black" />
          <BackButtonText>
            {intl.formatMessage({ id: 'backButton' })}
          </BackButtonText>
        </BackHeaderButton>
      );
    }, [activeTab, viewAllMode]);

    useEffect(() => {
      window.scrollTo(0, 0)
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage({
            id: 'tabExercisePrograms',
            defaultMessage: 'Programs',
            description: 'Tab item for exercise programs',
          }),
          icon: IconType.Back,
        })
      );
    }, [dispatch, intl]);

    useEffect(() => {
      dispatch(
        setLayoutHeader({
          title: viewAllMode === null ? intl.formatMessage({ id: 'accomplishments' }) : '',
        })
      );
    }, [dispatch, intl, activeTab, viewAllMode]);


    const handleBadgeClick = (badge: any) => {

      navigate(routes.PROGRESS + '/' + badge.slug, { state: { badge } });
      setParentView(viewAllMode === 'badgeDetails' ? parentView : viewAllMode);
      setViewAllMode('badgeDetails');
    };

    if (!accessToken || loading) {
      return <Loader />;
    }

    return (
      <ContentWidth>
        {backHeaderButton}
        <Fragment>

          <TabMenu
            menuId="my-overview"
            menu={tabMenu}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {activeTab === 1 && (
            <CardContainer>
              {viewAllMode === 'myBadges' && (
                <AllBadgesContainer>
                  {allUnlocked && allUnlocked.length > 0 ? (
                    <>
                      <Heading>{intl.formatMessage({ id: 'allMyBadges' })}</Heading>
                      {allUnlocked
                        .sort(
                          (a, b) =>
                            new Date(a.completed).getTime() -
                            new Date(b.completed).getTime()
                        )
                        .map((badge: AchievementResponseType) => {
                          const imageRef = badge.Image?.asset._ref;
                          const imageUrl = getImageUrl(imageRef);

                          return (
                            <HorizontalBadge
                              key={badge.slug}
                              image={imageUrl}
                              title={badge.title[language?.languageCode ?? 'en'] || 'Unknown Title'}
                              description={
                                badge.description[language?.languageCode ?? 'en'] ||
                                'No description available'
                              }
                              onClick={() => handleBadgeClick(badge)}
                              isLocked={
                                badge.amountToComplete !== null || badge.isLocked
                              }
                            />
                          );
                        })}
                    </>
                  ) : (
                    <AllBadgesContainer>
                      <Heading>{intl.formatMessage({ id: 'noUnlockedBadges' })}</Heading>
                    </AllBadgesContainer>
                  )}
                </AllBadgesContainer>
              )}

              {viewAllMode === 'lockedBadges' && (
                <AllBadgesContainer>
                  {allLockedBadges && allLockedBadges.length > 0 ? (
                    <>
                      <Heading>{intl.formatMessage({ id: 'allLockedBadges' })}</Heading>
                      {allLockedBadges
                        .map((badge: AchievementResponseType) => {
                          const imageRef = badge.Image?.asset._ref;
                          const imageUrl = getImageUrl(imageRef);
                          return (
                            <HorizontalBadge
                              key={badge.slug}
                              image={imageUrl}
                              title={badge.title[language?.languageCode ?? 'en'] || 'Unknown Title'}
                              description={
                                badge.description[language?.languageCode ?? 'en'] || 'Description Unavailable'
                              }
                              onClick={() => handleBadgeClick(badge)}
                              isLocked={badge.isLocked}
                            />
                          );
                        })}
                    </>
                  ) : (
                    <AllBadgesContainer>
                      <Heading>
                        {intl.formatMessage({ id: 'noMoreLockedBadges' })}
                      </Heading>
                    </AllBadgesContainer>
                  )}

                  <BackButtonWrapper>
                    <BackButton
                      className="link"
                      onClick={() => setViewAllMode(null)}
                    >
                      {intl.formatMessage({ id: 'backButton' })}
                    </BackButton>
                  </BackButtonWrapper>
                </AllBadgesContainer>
              )}

              {viewAllMode === 'badgeDetails' && (
                <BadgeDetails />
              )}

              {!viewAllMode && (
                <Fragment>
                  <NumberContainer>
                    <span className="number">{allUnlocked?.length ?? 0}</span>
                    <p className="badge-text">
                      {intl.formatMessage({ id: 'pageBadgesUnlocked' })}
                    </p>
                  </NumberContainer>

                  <ImageGrid>
                    {allUnlocked
                      ?.sort(
                        (a, b) =>
                          new Date(b.completed).getTime() -
                          new Date(a.completed).getTime()
                      )
                      .slice(0, 3)
                      .map((badge) => (
                        <ImageCard
                          key={badge.slug}
                          onClick={() => handleBadgeClick(badge)}>
                          <img
                            src={getImageUrl(badge.Image?.asset?._ref)}
                            alt={`Badge ${badge.title[language?.languageCode ?? 'en']}`}
                          />
                          <p className="image-date">
                            {BadgeformatDate(badge.completed)}
                          </p>
                          <p className="image-title">{badge.title[language?.languageCode ?? 'en']}</p>
                        </ImageCard>
                      ))}
                  </ImageGrid>

                  <TitleRow>
                    <h4 className="title">
                      {intl.formatMessage({ id: 'pageMyUnlockedBadgesTitle' })}
                    </h4>
                    <button
                      className="link"
                      onClick={() => setViewAllMode('myBadges')}
                    >
                      {intl.formatMessage({ id: 'pageSeeAllUnlockedBadges' })}
                    </button>
                  </TitleRow>

                  {allUnlocked && allUnlocked.length > 0 ? (
                    allUnlocked
                      .sort(
                        (a, b) =>
                          new Date(b.completed).getTime() -
                          new Date(a.completed).getTime()
                      )
                      .slice(0, 4)
                      .map((badge: AchievementResponseType) => {
                        const imageRef = badge.Image?.asset._ref;
                        const imageUrl = getImageUrl(imageRef);
                        return (
                          <HorizontalBadge
                            key={badge.slug}
                            image={imageUrl}
                            title={badge.title[language?.languageCode ?? 'en'] || 'Title Unavailable'}
                            description={
                              badge.description[language?.languageCode ?? 'en'] || 'Description Unavailable'
                            }
                            onClick={() => handleBadgeClick(badge)}
                            isLocked={
                              badge.amountToComplete !== null || badge.isLocked
                            }
                          />
                        );
                      })
                  ) : (
                    <AllBadgesContainer>
                      <Heading>{intl.formatMessage({ id: 'noUnlockedBadges' })}</Heading>
                    </AllBadgesContainer>
                  )}
                  <TitleRow>
                    <h4>
                      {intl.formatMessage({ id: 'pageMyLockedBadgesTitle' })}
                    </h4>
                    <button
                      className="link"
                      onClick={() => setViewAllMode('lockedBadges')}
                    >
                      {intl.formatMessage({ id: 'pageSeeAllLockedBadges' })}
                    </button>
                  </TitleRow>

                  {allLockedBadges && allLockedBadges.length > 0 ? (
                    allLockedBadges
                      .slice(-4)
                      .map((badge: AchievementResponseType) => {
                        const imageRef = badge.Image?.asset._ref;
                        const imageUrl = getImageUrl(imageRef);
                        return (
                          <HorizontalBadge
                            key={badge.slug}
                            image={imageUrl}
                            title={badge.title[language?.languageCode ?? 'en'] || 'Title Unavailable'}
                            description={
                              badge.description[language?.languageCode ?? 'en'] || 'Description Unavailable'
                            }
                            onClick={() => handleBadgeClick(badge)}
                            isLocked={
                              badge.amountToComplete !== null || badge.isLocked
                            }
                          />
                        );
                      })
                  ) : (
                    <AllBadgesContainer>
                      <Heading>
                        {intl.formatMessage({ id: 'noMoreLockedBadges' })}
                      </Heading>
                    </AllBadgesContainer>
                  )}
                </Fragment>
              )}
            </CardContainer>
          )}
          {activeTab === 2 && <Leaderboard />}
        </Fragment>
      </ContentWidth>

    )
  }
}

export default Progress;