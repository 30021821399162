import Lottie from 'lottie-react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeroSliderWrap = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 680px;

  @media (max-width: ${breakpoints.l}px) {
    overflow-x: hidden;
    max-width: none;
    margin: 0;
  }
  z-index: 1;
`;

export const SnowingLottie = styled(Lottie)`
  position: absolute;
  width: 110%;
  pointer-events: none;
  top: -200px;
  z-index: 2;

  @media (min-width: ${breakpoints.l}px) {
    top: -1365px;
  }
`;

export const Logo = styled.img`
  width: auto;
  height: 28px;
  position: absolute;

  @media (max-width: ${breakpoints.s}px) {
    height: 22px;
  }
`;

export const Banderole = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  background: rgba(147, 26, 212, 0.5);
  color: ${({ theme }) => theme.colors.black};
  border-radius: ${({ isMobile }) => (isMobile ? '0' : '8px')};
  padding: 1em 1em;
  margin-bottom: 1em;
  position: relative;

  @media (max-width: ${breakpoints.s}px) {
  padding: 1em 0.75em;
  }
`;

export const BanderoleText = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
    padding-left: 2em;
  }
`;
