import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonLink = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
`;