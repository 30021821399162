import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BadgeformatDate } from 'utils/date';
import { BadgeDetailsContainer, IconContainer, BadgeDescriptionContainer, ButtonContainer, Img } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon, { IconType } from 'components/UI/Icon';
import { useGetAchievementQuery } from 'store/achievementService/achievementService';
import { localizationSelector, setLayoutHeader } from 'store';
import { useDispatch } from 'react-redux';
import { getImage } from 'utils/asset';
import { useAppSelector } from 'hooks/redux';
import { GetAchievementStateType } from 'store/achievementService/endpointDefinitions';
import Button from 'components/UI/Button';
import { ContentWidth } from 'styles';

const BadgeDetails: FC = () => {

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const { slug } = useParams();
  const { data: badge } = useGetAchievementQuery({ slug: slug || '' });

  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'tabAccomplishments',
          defaultMessage: 'Badges',
          description: 'Tab item for badges',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);

  const image = useMemo(() => {
    if (badge?.Image) {
      const imageData = getImage(badge.Image);
      return {
        ...imageData,
        src: imageData?.src.split('?')[0]
      };
    }
    return null;
  }, [badge]);

  const handleNavigateToChallenge = useMemo(() => (badge: GetAchievementStateType['result']) => {
    const matchingReference = badge.references?.find((ref) => {
      if (!ref.slug.current || !badge.slug) return false;

      const cleanRefSlug = ref.slug.current.toLowerCase().replace(/[^a-z0-9]/g, '');
      const cleanBadgeSlug = badge.slug.toLowerCase().replace(/[^a-z0-9]/g, '');

      const refSegments = cleanRefSlug.match(/\d+|\D+/g) || [];
      const badgeSegments = cleanBadgeSlug.match(/\d+|\D+/g) || [];

      const matchingSegments = refSegments.filter(segment =>
        badgeSegments.some(badgeSegment =>
          segment === badgeSegment ||
          segment.includes(badgeSegment) ||
          badgeSegment.includes(segment)
        )
      );

      const similarityScore = matchingSegments.length / Math.max(refSegments.length, badgeSegments.length);
      return similarityScore > 0.2;
    });

    if (matchingReference) {
      const challengePath = `/compete/available-challenges/${matchingReference.slug.current}`;
      navigate(challengePath);
    }
  }, [navigate]);

  if (!badge) {
    return null;
  }


  return (
    <ContentWidth>
      <BadgeDetailsContainer>
        <div className="modal-content">
          <div className="badge-image">
            <Img isLocked={badge.isLocked} src={image!.src!} alt="Badge Image" />
          </div>
          <div className="badge-details">
            <h6>
              <IconContainer>
                <Icon type={IconType.Calendar} color="grayText" size={22} />
              </IconContainer>
              {badge && badge.completed === null
                ? intl.formatMessage({ id: 'pageNotYetUnlocked' })
                : <span>{intl.formatMessage({ id: 'pageUnlockedDate' })} {BadgeformatDate(badge.completed ?? '')}</span>
              }
            </h6>
            <h2>
              {badge.isLocked
                ? `${''}`
                : `${intl.formatMessage({ id: 'newUnlockedAchievement' }) + " " + (badge.title ? badge.title[language?.languageCode ?? 'en'] : '')} `}
            </h2>
            <BadgeDescriptionContainer>
              <p>
                {badge.description && badge.description[language?.languageCode ?? 'en']}
                <br />
                <strong>
                  {badge.isLocked
                    ? intl.formatMessage({ id: 'badgeDescriptionTitle' })
                    : intl.formatMessage({ id: 'badgeCongratulationsTitle' })}
                </strong>
                {' '}
                {badge.isLocked
                  ? intl.formatMessage({ id: 'badgeLockedDescription' })
                  : intl.formatMessage({ id: 'badgeUnlockedDescription' })
                }
              </p>
              <ButtonContainer>
                {badge.references && badge.references.length > 0 &&
                  <Button
                    size="large"
                    color="white"
                    background="blue"
                    border="backgroundDark"
                    rounded
                    width='100%'
                    onClick={() => { handleNavigateToChallenge(badge) }}
                  >
                    <FormattedMessage
                      id={'goToChallengeButton'}
                    />
                  </Button>
                }
              </ButtonContainer>
            </BadgeDescriptionContainer>
          </div>
        </div>
      </BadgeDetailsContainer>
    </ContentWidth>
  );
};

export default BadgeDetails;
