import { Marker } from '@react-google-maps/api';
import { FC } from 'react';

import goalBadge from 'assets/vectors/map-goal-marker.png';
import goalPinkBadge from 'assets/vectors/map-pink-goal-marker.png';
import goalChristmasBadge from 'assets/vectors/map-christmas-goal-marker.svg';
import goalValentineBadge from 'assets/vectors/map-valentine-goal-marker.svg';
import { SpecialContestType } from 'models';

type Props = {
  specialContest?: SpecialContestType;
  position: {
    lat: number;
    lng: number;
  };
};

const SIZE = 36;
const ANCHOR_X = SIZE / 2;
const ANCHOR_Y = ANCHOR_X / 2;

const MapGoalMarker: FC<Props> = ({ position, specialContest }) => {
  const christmasCompetition = specialContest === SpecialContestType.Christmas;
  const pinkCompetition = specialContest === SpecialContestType.Pink;
  const valentineCompetition = specialContest === SpecialContestType.Valentine;

  return (
    <Marker
      icon={{
        url:
          pinkCompetition ? goalPinkBadge :
            christmasCompetition ? goalChristmasBadge :
              valentineCompetition ? goalValentineBadge : goalBadge,
        size: new google.maps.Size(SIZE, SIZE),
        scaledSize: new google.maps.Size(SIZE, SIZE),
        anchor: new google.maps.Point(ANCHOR_X, ANCHOR_Y),
        origin: new google.maps.Point(0, 0),
      }}
      position={position}
    />
  );
};

export default MapGoalMarker;