interface Languages {
  en: string;
  sv: string;
  no: string;
  da: string;
}

type ContentTranslations = {
  [key: string]: Languages;
};

const translations: ContentTranslations = {
  article: {
    en: 'Article',
    sv: 'Artikel',
    no: 'Artikkel',
    da: 'Artikel'
  },
  articleCategory: {
    en: 'Article Category',
    sv: 'Artikelkategori',
    no: 'Artikkelkategori',
    da: 'Artikelkategori'
  },
  exercise: {
    en: 'Exercise',
    sv: 'Övning',
    no: 'Øvelse',
    da: 'Øvelse'
  },
  exerciseCategory: {
    en: 'Exercise Category',
    sv: 'Övningskategori',
    no: 'Øvelsekategori',
    da: 'Øvelseskategori'
  },
  exerciseProgram: {
    en: 'Exercise Program',
    sv: 'Träningsprogram',
    no: 'Treningsprogram',
    da: 'Træningsprogram'
  },
  exerciseProgramCategory: {
    en: 'Exercise Program Category',
    sv: 'Övningsprogramkategori',
    no: 'Treningsprogramkategori',
    da: 'Træningsprogramkategori'
  },
  recipe: {
    en: 'Recipe',
    sv: 'Recept',
    no: 'Oppskrift',
    da: 'Opskrift'
  },
  diet: {
    en: 'Diet',
    sv: 'Kost',
    no: 'Kosthold',
    da: 'Kost'
  },
  challenge: {
    en: 'Challenge',
    sv: 'Utmaning',
    no: 'Utfordring',
    da: 'Udfordring'
  },
  challengeCategory: {
    en: 'Challenge Category',
    sv: 'Utmaningskategori',
    no: 'Utfordringskategori',
    da: 'Udfordringskategori'
  }
};

const defaultTranslations: Languages = {
  en: 'Other',
  sv: 'Övrigt',
  no: 'Annet',
  da: 'Andet'
};

export type LanguageCode = 'en' | 'sv' | 'no' | 'da';

export const getTypeTranslation = (type: string, language: LanguageCode): string => {
  const translation = translations[type];
  if (translation) {
    return translation[language] ?? translation.en ?? defaultTranslations[language];
  }
  return defaultTranslations[language];
};