import styled, { keyframes } from 'styled-components';
import { breakpoints } from 'styles';

export const CardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 0px;


  @media (max-width: ${breakpoints.xs}px) {
    box-shadow: none;
    padding: 10px;
  }
  @media (max-width: ${breakpoints.s}px) {
    box-shadow: none;
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3em;

  .number {
    font-size: 3.5rem;
    font-weight: bold;
    color: #333;
  }

  .badge-text {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.grayText};
  }
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
  @media (max-width: ${breakpoints.xs}px) {
    gap: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    gap: 0px;
  }
`;

export const ImageCard = styled.div`
  text-align: center;
  cursor: pointer;

  img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    }

    .image-title {
      font-size: 1rem;
      margin: 0;
      font-weight: bold;
      }

    .image-date {
      color: ${({ theme }) => theme.colors.grayText};
      font-size: 0.90rem;
      font-weight: 500;
      margin-top: 0.75rem;
      margin-bottom: 0;
      color: #888;
    }
  @media (max-width: ${breakpoints.xs}px) {
    img {
      width: 93px;
      height: 93px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    img {
      width: 93px;
      height: 93px;
    }
  }
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .link {
    background: none;
    border: none;
    padding: 0;
    color: black;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
`;

export const AllBadgesContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  >* {font-weight: 500 !important;}
  color: ${({ theme }) => theme.colors.grayText};
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 80px;
  width: 99%;
  margin: 0 auto;
  @media (max-width: ${breakpoints.xs}px) {
    width: 90%;
  }
  `;
export const BackHeaderButton = styled.button`
  display: flex;
  position: absolute;
  top: -7em;
  left: -0.8em;
  padding: 10px;
  text-align: center;
  align-items: center;
  background-color: transparent;
  gap: 0.2em;
  border: none;
  cursor: pointer;
  font-size: 16px;

  > svg {
    scale: 1.2;
    }

  @media (max-width: ${breakpoints.m}px) {
    position: relative;
    top: 0em;
    left: 0;
    width: 100%;
  }
`;

export const BackButton = styled.button`
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const BackButtonText = styled.span`
  font-size: 1rem;
  margin-top: 0.1em;
  font-weight: 700;
`;

export const MarqueeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const marquee = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }

`;

export const RecentBadgesRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: max-content;
  gap: 20px;
  position: relative;
  margin-bottom: 80px;

  animation: ${marquee} 10s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

export const Heading = styled.h3`
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
  margin-bottom: 20px;
  font-size: 24px;
`;
export const ExpandButton = styled.div`
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    cursor: pointer;
    font-size: 1rem;
    text-align:center;
    width:94%;


  }
`;

export const UserRankBadge = styled.div`
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 640px;
  border-radius: 10px;
  border-top: 2px solid silver;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 5.2); /* Added box-shadow */

  @media (max-width: ${breakpoints.xs}px) {
    border-radius: 5px;
    width: 95%;
    max-width: 350px;
    bottom: 78px;
  }
`;

export const BadgeDetailsContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-content {
    .badge-image {
      margin-bottom: 20px;

      img {
        width: 250px;
        height: 250px;
        border-radius: 10px;
      }
    }

    .badge-details {
      margin-bottom: 20px;

      h2 {
        margin-bottom: 10px;
        font-size: 1.5rem;
        color: #222;
      }

      h6 {
        display: inline-block;
        border-radius: 10px;
        padding: 0 10px;
        background-color: #e0e0e0;
      }

      p {
        font-size: 1rem;
        color: black;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .continue-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 90%;
    margin-left: -3px;

    .modal-content {
      .badge-image img {
        width: 150px;
        height: 150px;
      }
    }
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  > * {
    flex: 1;
    margin: 0 5px;
    text-align: center;
  }

  @media (max-width: ${breakpoints.xs}px) {
    margin: 20px 18px;
  }
`;

export const ToggleButton = styled.button<{ isActive?: boolean }>`
  padding: 10px 20px;
  font-size: 16px;

  border: 2px solid ${({ isActive }) => (isActive ? '#007bff' : '#ddd')};
  border-radius: 15px;
  background-color: ${({ isActive }) => (isActive ? '#007bff' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#007bff')};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ isActive }) => (isActive ? '#0056b3' : '#f0f0f0')};
    color: ${({ isActive }) => (isActive ? '#fff' : '#0056b3')};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ isActive }) => (isActive ? '#007bff' : '#ddd')};
  }
`;
