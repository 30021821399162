import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLocalizedText } from 'hooks/useLocalizedText';
import {
  localizationSelector,
  setLayoutHeader,
  useGetCompanyCommunicationQuery,
  useGetCompanyContentQuery,
  useGetLatestArticlesQuery,
  useGetMembershipSettingsQuery,
  useGetStartPageQuery,
  useGetUserGreetingQuery,
  useGetUserQuery,
} from 'store';
import { ContentWidth, Gap, SmallGap } from 'styles';
import { getImage } from 'utils/asset';
import { getRefLink } from 'utils/link';

import ImageCard from 'components/UI/Cards/ImageCard';
import DragSlider from 'components/UI/DragSlider';
import Heading, { Tag } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import HeroLarge from 'components/UI/Heros/HeroLarge';
import Loader from 'components/UI/Loader';
import EmptyState from 'components/UI/EmptyState';
import CompanyCommunication from 'components/fragments/CompanyCommunication';
import { Banderole, BanderoleText, HeroSliderWrap, Logo, SnowingLottie } from './styles';
import ActivitiesWeekly from 'components/fragments/ActivitiesWeekly';
import { Activity, ActivityToplistQuery, ReferenceType, TabMenuItem } from 'models';
import ContentModal from 'components/modals/ContentModal';
import TabMenu from 'components/UI/TabMenu';
import ChallengeCard from 'components/cards/ChallengeCard';
import { useGetDailyTimedChallengeQuery } from 'store/timedChallengeService/timedChallengeService';
import { ChallengeType } from 'models/timedChallengeResult/timedChallengeResult';
import TopListOverview from 'components/fragments/TopListOverview';
import { companiesSpecificOnboardingStep } from 'constants/onboardingGuids';
import { useGetAssetImage } from 'hooks/useGetAssetImage';
import useIsMobile from 'hooks/useIsMobile';
import { getTypeTranslation } from 'utils/contentTypeTranslations';


const Overview: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getText = useLocalizedText();
  const isMobile = useIsMobile();
  const { language } = useAppSelector(localizationSelector);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/overview' && location.hash === '#{{$terms_link}}') {
      window.location.href = 'https://charma.io/p/conditions';
    }
    if (location.pathname === '/overview' && location.hash === '#{{$policy_link}}') {
      window.location.href = 'https://charma.io/p/privacy';
    }
  }, [location]);

  // Hooks
  const user = useGetUserQuery();

  const settings = useGetMembershipSettingsQuery();
  const greeting = useGetUserGreetingQuery({
    language: language?.languageCode,
  });
  const startPage = useGetStartPageQuery({ language: language?.languageCode });
  const latestArticles = useGetLatestArticlesQuery({
    limit: 6,
    language: language?.languageCode,
  });

  const { data: ccData } = useGetCompanyCommunicationQuery({
    language: language?.languageCode,
  });

  const companyId = user.data?.membership.companyId;
  const { data: companyContent } = useGetCompanyContentQuery(
    { companyId: companyId ?? '' },
    { skip: !companyId }
  );


  //state
  const [progress, setProgress] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number | string>(1);
  const [hasToplistNotification, setHasToplistNotification] = useState(false);
  const [lastDailyChallengeFetch, setLastDailyChallengeFetch] = useState(0);
  const [topListInfoModalOpen, setTopListInfoModalOpen] =
    useState<boolean>(false);

  const RE_FETCH_TIMEOUT_MS = 1000 * 10

  const { data: dailyChallenge, isLoading: dailyChallengeIsLoading } = useGetDailyTimedChallengeQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: lastDailyChallengeFetch + RE_FETCH_TIMEOUT_MS < Date.now(),
  });

  useEffect(() => {
    setLastDailyChallengeFetch(Date.now());
  }, [dailyChallenge]);

  // Tab menu
  const tabMenu: TabMenuItem[] = useMemo(() => {
    return [
      {
        id: 1,
        text: intl.formatMessage({
          id: "pageMyHealthActivityTitle",
          defaultMessage: "Activity",
          description: "Section title for activity"
        }),
        hasNotification: false,
      },
      {
        id: 2,
        text: intl.formatMessage({
          id: 'tabToplist',
          defaultMessage: 'Toplist',
          description: 'Tab item for toplist',
        }),
        hasNotification: hasToplistNotification,
      },
    ];
  }, []);

  const DailyChallengeCard = useMemo(() => {
    if (dailyChallengeIsLoading) {
      return <Loader color="blue" padding />;
    }

    if (!dailyChallenge || !user.data) {
      return null;
    }

    const {
      membership,
    } = user.data
    return (
      <ChallengeCard
        challenge={dailyChallenge}
        language={language?.languageCode}
        userId={membership.userId}
        membershipId={membership.id}
        progress={progress}
      />
    );
  }, [dailyChallenge, dailyChallengeIsLoading, language?.languageCode, progress, user.data]);

  // Set header
  useEffect(() => {
    if (user.data) {
      const { firstName } = user.data;
      dispatch(
        setLayoutHeader({
          title: intl.formatMessage(
            {
              id: 'pageOverviewGreetings',
              defaultMessage: 'Hello {firstName}!',
              description: 'Overview page greetings text',
            },
            { firstName }
          ),
          inverted: true,
        })
      );
    }
  }, [dispatch, intl, user]);

  useEffect(() => {
    if (settings.data) {
      setHasToplistNotification(settings.data.showInToplistMyCompany === false);
    }
  }, [settings.data]);

  const handleActivitiesChange = useCallback((activities: Activity[]) => {
    if (!dailyChallenge || !activities) {
      return;
    }

    // Get today's date
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getActivitiesToday = (activities: Activity[], today: Date) => {
      return activities.filter((activity: Activity) => {
        const activityDate = new Date(activity.timestamp);
        activityDate.setHours(0, 0, 0, 0);
        return activityDate.getTime() === today.getTime();
      });
    };

    const calculateSteps = (activities: Activity[]) => {
      return activities.reduce((sum: number, activity: Activity) => {
        return sum + activity.value.stepsAwarded;
      }, 0);
    };

    const calculateMinutes = (activities: Activity[]) => {
      return activities.reduce((sum: number, activity: Activity) => {
        return sum + activity.value.numberOfUnits;
      }, 0);
    };

    const calculateProgress = (current: number, total: number) => {
      return (current / total) * 100;
    };

    const activitiesToday = getActivitiesToday(activities, today);
    const dailySteps = calculateSteps(activitiesToday);

    if (dailyChallenge?.challengeType === ChallengeType.Activity) {
      if (dailyChallenge?.allowAllActivities) {
        setProgress(calculateProgress(dailySteps, dailyChallenge?.amountToComplete));
      } else {
        const validDailyActivities = activitiesToday.filter((activity: Activity) => {
          return dailyChallenge?.allowedActivities.includes(activity.value.activitySlug);
        });

        const validDailySteps = calculateSteps(validDailyActivities);
        const validDailyMinutes = calculateMinutes(validDailyActivities);

        if (dailyChallenge.unit && dailyChallenge?.unit.slug === 'minute') {
          setProgress(calculateProgress(validDailyMinutes, dailyChallenge?.amountToComplete));
        } else {
          setProgress(calculateProgress(validDailySteps, dailyChallenge?.amountToComplete));
        }
      }
    }
  }, [dailyChallenge]);

  const companyLogoAssetId = ccData?.company?.logoAssetId ?? ccData?.umbrellaCompany?.logoAssetId;
  const [companyLogoSrc] = useGetAssetImage(companyLogoAssetId);

  const visionBanner = useMemo(() => {
    const visionCustomBanner = companiesSpecificOnboardingStep.some((company) => user.data?.membership.companyId === company.Id && company.Company === "Vision");
    if (visionCustomBanner) {
      return (
        <Banderole isMobile={isMobile}>
          {companyLogoSrc && (
            <Logo src={companyLogoSrc} alt={'Company logo'} />
          )}
          <BanderoleText>
            <FormattedMessage
              id="pageOverviewVisionBanderoll"
              defaultMessage="Home data not found"
              description="Empty state for overview page"
            />
          </BanderoleText>
        </Banderole>
      )
    }
  }, [companyLogoSrc, isMobile, user.data?.membership.companyId]);


  // Navigate
  const onNavigate = useCallback(
    (link: string) => () => navigate(link),
    [navigate]
  );
  const onOpenTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(true),
    []
  );
  const onCloseTopListInfoModal = useCallback(
    () => setTopListInfoModalOpen(false),
    []
  );

  // Loading
  if (startPage.isLoading || latestArticles.isLoading || greeting.isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!startPage.data || !latestArticles.data || !greeting.data) {
    return (
      <EmptyState iconType={IconType.Home} padding>
        <FormattedMessage
          id="pageOverviewEmptyState"
          defaultMessage="Home data not found"
          description="Empty state for overview page"
        />
      </EmptyState>
    );
  }

  const [{ startPageGroups }] = startPage.data;
  const firstStartPageGroup = startPageGroups[0];
  const restStartPageGroups = startPageGroups.slice(1);
  const { active, title, contentReference } = firstStartPageGroup;

  const umbrellaCompanyCommunicationContent =
    ccData?.umbrellaCompany?.companyCommunicationContent.filter(
      ({ displayToUser }) => displayToUser
    ) ?? [];

  // Show inspiration slider if no company communication content
  const noCompanyCommunicationContent =
    ccData?.company?.companyCommunicationContent?.length === 0 &&
    umbrellaCompanyCommunicationContent.length === 0;


  return (
    <ContentWidth>
      <Fragment>
        <HeroSliderWrap>
          <DragSlider id="hero-slider" slidesPerView={1} isHero>
            {greeting.data.map((item, index) => (
              <Fragment key={index}>
                {/* <SnowingLottie key={`snow-${index}`} animationData={animationData} /> */}
                <HeroLarge
                  key={index}
                  onClick={onNavigate(
                    getRefLink(item.referenceType, item.reference)
                  )}
                  buttonText={getText(item.buttonText)}
                  title={getText(item.title)}
                  text={getText(item.contentString)}
                  image={getImage(item.image)}
                />
              </Fragment>
            ))}
          </DragSlider>
        </HeroSliderWrap>
        {visionBanner && visionBanner}
        {companyContent && companyContent.content &&
          <ContentWidth isSurface style={{ marginTop: '0' }}>
            <Heading tag={Tag.H4}>{getText(companyContent.headingTitle)}</Heading>
            <Fragment>
              <DragSlider id="company-content-slider">
                {companyContent.content.map((ref, j) => {
                  const { image, title, _type, slug } = ref;
                  const imageObj = image ? {
                    src: image,
                    alt: getText(title) || ''
                  } : null;

                  return (
                    <ImageCard
                      key={`ref-${j}`}
                      category={getTypeTranslation(_type!, language?.languageCode!)}
                      image={imageObj}
                      title={getText(title)}
                      onClick={slug ? onNavigate(getRefLink(_type as ReferenceType, slug)) : undefined}
                      size="small"
                    />
                  );
                })}
              </DragSlider>
            </Fragment>
          </ContentWidth>
        }

        {active &&
          <ContentWidth isSurface style={{ marginTop: '0' }}>
            <Fragment>
              <Heading tag={Tag.H4}>{getText(title)}</Heading>
              <DragSlider id="slider">
                {contentReference.map((ref, j) => {
                  const { category, image, title, _type, slug } = ref;
                  return (
                    <ImageCard
                      key={`ref-${j}`}
                      category={getText(category.title)}
                      image={getImage(image)}
                      title={getText(title)}
                      onClick={onNavigate(getRefLink(_type, slug.current))}
                      size="small"
                    />
                  );
                })}
              </DragSlider>
            </Fragment>
          </ContentWidth>
        }
        {DailyChallengeCard}
        <SmallGap />
        <ContentWidth isSurface style={{ paddingBottom: '0px', marginBottom: '0px' }}>
          <TabMenu
            menuId="my-overview"
            menu={tabMenu}
            activeTab={activeTab}
            setActiveTab={setActiveTab} />
        </ContentWidth>
        <ContentWidth isSurface style={{ paddingBottom: '0px' }}>
          {activeTab === 2 ? (
            <TopListOverview />
          ) : (
            <ContentWidth>
              <ActivitiesWeekly showLatest onActivitiesChange={handleActivitiesChange} />
            </ContentWidth>
          )
          }
          {restStartPageGroups.map(({ active, title, contentReference }, i) => {
            if (!active) {
              return null;
            }
            return (
              <Fragment key={i}>
                <SmallGap />
                <Heading tag={Tag.H4}>{getText(title)}</Heading>
                <DragSlider id={`slider-${i}`}>
                  {contentReference.map((ref, j) => {
                    const { category, image, title, _type, slug } = ref;
                    return (
                      <ImageCard
                        key={`ref-${j}`}
                        category={getText(category.title)}
                        image={getImage(image)}
                        title={getText(title)}
                        onClick={onNavigate(getRefLink(_type, slug.current))}
                        size="small" />
                    );
                  })}
                </DragSlider>
              </Fragment>
            );
          })}
          <Gap />
          {noCompanyCommunicationContent ? (
            <Fragment>
              <Heading tag={Tag.H4}>
                <FormattedMessage
                  id="pageOverviewInspirationTitle"
                  defaultMessage="Inspiration"
                  description="Title for inspiration section on overview page" />
              </Heading>
              <DragSlider id="inspiration" slidesPerView={2}>
                {latestArticles.data.map((item) => (
                  <ImageCard
                    key={item._id}
                    category={getText(item.category.title)}
                    title={getText(item.title)}
                    image={getImage(item.image)}
                    onClick={onNavigate(getRefLink('article', item.slug))}
                    size="large" />
                ))}
              </DragSlider>
              <Gap />
            </Fragment>
          ) : (
            <CompanyCommunication />
          )}
          <ContentModal
            isOpen={topListInfoModalOpen}
            onClose={onCloseTopListInfoModal}
            title={intl.formatMessage({
              id: 'pageCompetitionTopListTitle',
              defaultMessage: 'Top list',
              description: 'Title for top list section on competition page',
            })}
          >
            <FormattedMessage
              id="pageOverviewTopListInfo"
              defaultMessage={`
            You can see your status and a leaderboard for all users in your company.{NewLine}{NewLine}
             Wellr points:{NewLine}
              Here, your status is presented, including the percentage of your total points contributed by you.{NewLine}{NewLine}
               Steps:{NewLine}
             Here, a leaderboard is presented for individuals who have taken the most steps, along with the percentage of the total distance they have covered (if you have chosen to display it).`}
              description="Info text for top list on competition page"
              values={{ NewLine: <br /> }}
              tagName={'p'}
            />
          </ContentModal>
        </ContentWidth>
      </Fragment>
    </ContentWidth>
  );
};

export default Overview;