import styled from 'styled-components';
import { breakpoints } from 'styles';

export const TabMenuContainer = styled.div`
  width: 250px;

  @media (max-width: ${breakpoints.s}px) {
    width: 200px;
    }
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  > button {
   padding: 0px;}
`;

export const IconContainer = styled.div`
  > svg {
   margin-top: 0.2rem;
   }
   `;

export const InnerButtonDetails = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-top: 0.2em;
   color: ${({ theme }) => theme.colors.grayText};
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  object-fit: cover;
  margin-right: 0px;
`;

export const ImgContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 99px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
`;


export const Img = styled.img`
  border-radius: 99px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h2{
  margin: 0;
  padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  > * {
  color: ${({ theme }) => theme.colors.grayText};
}
`;

export const Container = styled.div`
  padding: 0.5em 0.5em;
`;

export const RightText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.35em;
  padding-right: 0.5em;

  > * {
  color: ${({ theme }) => theme.colors.grayText};
  object-fit: fill;
  }
  > svg, icon, Icon, img, Img {
   width: 2.2em;
   height: 1.7em;
  }
`;

export const UserContainer = styled.div`
  display: flex;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0.2em;
  font-weight: 500;
  max-width: 300px;

  > * {
  color: ${({ theme }) => theme.colors.grayText};
  overflow: hidden;
  text-overflow: ellipsis;
  }

  b {
  font-size: 1.2rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    max-width: 120px;
    }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;

  > button, span {
    padding: 0px;
    // font-size: 0.85rem !important;
  }

`;

export const ContentContainer = styled.div`
  display: flex;
  padding: 0em 0.5em;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4em 9.5em;

  h2{
  margin: 0;
  padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  > * {
  color: ${({ theme }) => theme.colors.grayText};
}
`;

export const SettingsContainer = styled.div`
  margin: 0.5em 0;
  > *, h3 > *{
    padding: 0 0 0 0;
  }
`;