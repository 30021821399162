import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGetAssetImage } from 'hooks/useGetAssetImage';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authSelector, setLayoutHeader, useGetUserQuery } from 'store';
import { calculateBMI } from 'utils/health';
import { calculateAge } from 'utils/date';
import * as routes from 'router/routes';

import Loader from 'components/UI/Loader';
import Icon, { IconType } from 'components/UI/Icon';
import Heading from 'components/UI/Heading';
import EmptyState from 'components/UI/EmptyState';
import HeroSmall from 'components/UI/Heros/HeroSmall';
import DataItem from 'components/UI/DataItem';

import {
  Img,
  Input,
  ProfileHeader,
  ProfileHeaderData,
  ProfileHeaderDataGrid,
  ProfileImage,
} from './styles';
import UserSettingsList from 'components/fragments/UserSettingsList';
import { uploadAsset } from 'services';

const Profile: FC = () => {
  // Hooks
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { accessToken } = useAppSelector(authSelector);
  const { data: user, refetch, isLoading } = useGetUserQuery();

  const [profileImage, fetchAsset] = useGetAssetImage(user?.profilePictureAssetId);

  // Refs
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onClickImage = useCallback(() => inputFileRef.current?.click(), []);

  // Upload profile picture
  const onFileChangeCapture = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        await uploadAsset(files[0], 'ProfilePicture', accessToken);
        await refetch();
        fetchAsset();
      }
    },
    [refetch, fetchAsset, accessToken]
  );

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'profileTitle',
          defaultMessage: 'My profile',
          description: 'Title for my profile page',
        }),
        link: routes.SETTINGS,
        icon: IconType.Settings,
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  // Loading
  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!user) {
    return (
      <EmptyState iconType={IconType.Food} padding>
        <FormattedMessage
          id="pageProfileEmptyState"
          defaultMessage="Profile not found"
          description="Empty state for profile page"
        />
      </EmptyState>
    );
  }

  const { firstName, lastName, dateOfBirth, weightKg, lengthCm } = user;

  return (
    <Fragment>
      <HeroSmall>
        <ProfileHeader>
          <ProfileImage onClick={onClickImage}>
            {profileImage ?
              (
                <Img src={profileImage} alt={firstName} />
              )
              : (
                <Icon type={IconType.User} color="grayText" size={32} />
              )}
            <Input
              type="file"
              ref={inputFileRef}
              onChangeCapture={onFileChangeCapture}
            />
          </ProfileImage>
          <ProfileHeaderData>
            <Heading>
              {firstName} {lastName}
            </Heading>
            <ProfileHeaderDataGrid>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileAgeDescription',
                  defaultMessage: 'Age',
                  description: 'Profile page age description',
                })}
              >
                {dateOfBirth ? `${calculateAge(dateOfBirth)}` : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileLengthDescription',
                  defaultMessage: 'Length',
                  description: 'Profile page length description',
                })}
              >
                {lengthCm ? (
                  <FormattedMessage
                    id="pageProfileLengthValue"
                    defaultMessage="{value} cm"
                    description="Profile page length value"
                    values={{ value: lengthCm }}
                  />
                ) : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileWeightDescription',
                  defaultMessage: 'Weight',
                  description: 'Profile page weight description',
                })}
              >
                {weightKg ? (
                  <FormattedMessage
                    id="pageProfileWeightValue"
                    defaultMessage="{value} kg"
                    description="Profile page weight value"
                    values={{ value: weightKg }}
                  />
                ) : null}
              </DataItem>
              <DataItem
                description={intl.formatMessage({
                  id: 'pageProfileBMIDescription',
                  defaultMessage: 'BMI',
                  description: 'Profile page BMI description',
                })}
              >
                {weightKg && lengthCm
                  ? calculateBMI(weightKg, lengthCm).toFixed(1)
                  : null}
              </DataItem>
            </ProfileHeaderDataGrid>
          </ProfileHeaderData>
        </ProfileHeader>
      </HeroSmall>
      <UserSettingsList />
    </Fragment>
  );
};

export default Profile;
