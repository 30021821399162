import LedarshipProfile from 'components/fragments/LedarshipProfile';
import defaultprofile from '../../../assets/RankIcon/profile.svg';
import footstep from '../../../assets/RankIcon/footstep.png';
import wellrpoint from '../../../assets/RankIcon/Wellr.png';
import Loader from 'components/UI/Loader';
import LeadershipToggleButtons from 'components/UI/ToggleButton/LeadershipToggleButtons';
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useGetTotalActivityMyCompanyQuery, useGetTotalActivityAllCompaniesQuery, useGetMembershipSettingsQuery, useGetUserQuery } from 'store';
import { getProfileImage } from 'utils/asset';
import { dateStringToISOString, getFirstDateOfMonth } from 'utils/date';
import { CardContainer, TopThreeContainer, LeadershipProfileWrapper, UserRankBadge, BackButtonWrapper, ExpandButton } from './styles';
import { useAppSelector } from 'hooks/redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import TopListSettings from 'components/fragments/TopListSettings';
import TopListSettingsAllCompanies from 'components/fragments/TopListSettingsAllCompanies';
import { useLocation } from 'react-router-dom';
import { TabOptionsLeaderboard } from 'models';
import { IconType } from 'components/UI/Icon';
import EmptyState from 'components/UI/EmptyState';

const Leaderboard = () => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const settings = useGetMembershipSettingsQuery();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { data: user } = useGetUserQuery();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const { data: LocalUserlist } = useGetTotalActivityMyCompanyQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  const { data: GlobalUserlist } = useGetTotalActivityAllCompaniesQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  const activeTabFromTopListOverview = (location.state?.tab as TabOptionsLeaderboard);

  // States
  const [activePage, setActivePage] = useState<TabOptionsLeaderboard>(activeTabFromTopListOverview ?? TabOptionsLeaderboard.allCompanies);
  const [isWellrSteps, setIsWellrSteps] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [profilesToShow, setProfilesToShow] = useState(10);

  const [LocalProfileImages, setLocalProfileImages] = useState<Record<string, string>>({});
  const [GlobalProfileImages, setGlobalProfileImages] = useState<Record<string, string>>({});


  useEffect(() => {
    setProfilesToShow(10);
    setShowMore(false);
    setIsWellrSteps(false);
  }, [dispatch, intl, activePage]);

  const userId = user?.id;

  const handleShowMore = () => {
    setShowMore(true);
    setProfilesToShow(profilesToShow + 5);
  };

  const handlePageSwitch = (page: TabOptionsLeaderboard) => {
    setActivePage(page);
  };

  const localProfileData = useMemo(() => {
    const sortedActivity = LocalUserlist?.userActivity
      ? [...LocalUserlist.userActivity]
        .sort((a, b) => isWellrSteps ? b.steps - a.steps : b.points - a.points)
        .map((profile, index) => ({
          ...profile,
          rank: (index + 1).toString()
        }))
      : [];

    const LocaltopProfiles = sortedActivity.slice(0, 10);
    const remainingLocalProfiles = sortedActivity.slice(10);

    const MylocaluserRank = sortedActivity.findIndex(
      (activity) => activity.userId === userId
    ) + 1;


    const myLocaluserProfile = sortedActivity.find(
      (profile) => profile.userId === userId
    );

    return { LocaltopProfiles, remainingLocalProfiles, MylocaluserRank, myLocaluserProfile };
  }, [LocalUserlist, userId, isWellrSteps]);

  const globalProfileData = useMemo(() => {
    const sortedActivity = GlobalUserlist?.userActivity
      ? [...GlobalUserlist.userActivity]
        .sort((a, b) => isWellrSteps ? b.steps - a.steps : b.points - a.points)
        .map((profile, index) => ({
          ...profile,
          rank: (index + 1).toString()
        }))
      : [];

    const GlobaltopProfiles = sortedActivity.slice(0, 10);
    const remainingGlobalProfiles = sortedActivity.slice(10);

    const MyglobaluserRank = sortedActivity.findIndex(
      (activity) => activity.userId === userId
    ) + 1;

    const myGlobaluserProfile = sortedActivity.find(
      (profile) => profile.userId === userId
    );

    return { GlobaltopProfiles, remainingGlobalProfiles, MyglobaluserRank, myGlobaluserProfile };
  }, [GlobalUserlist, userId, isWellrSteps]);

  useEffect(() => {
    const fetchImages = async () => {
      if (LocalUserlist && LocalUserlist.userActivity.length > 0) {
        const images = await Promise.all(
          LocalUserlist.userActivity.map(async (profile) => {
            if (!profile.profilePictureAssetId) {
              return [profile.userId, defaultprofile];
            }
            try {
              const imageUrl = await getProfileImage(
                profile.profilePictureAssetId,
                accessToken || ''
              );
              return [profile.userId, imageUrl ?? defaultprofile];
            } catch (error) {
              console.error('Failed to fetch image:', error);
              return [profile.userId, defaultprofile];
            }
          })
        );
        setLocalProfileImages(Object.fromEntries(images));
      }
    };

    fetchImages();
  }, [LocalUserlist, accessToken]);

  // Fetch Globa profile images
  useEffect(() => {
    const fetchImages = async () => {
      if (GlobalUserlist && GlobalUserlist.userActivity.length > 0) {
        const images = await Promise.all(
          GlobalUserlist.userActivity.map(async (profile) => {
            if (!profile.profilePictureAssetId) {
              return [profile.userId, defaultprofile];
            }
            try {
              const imageUrl = await getProfileImage(
                profile.profilePictureAssetId,
                accessToken || ''
              );
              return [profile.userId, imageUrl ?? defaultprofile];
            } catch (error) {
              console.error(
                'Failed to fetch image for profile ID:',
                profile.userId,
                error
              );
              return [profile.userId, defaultprofile];
            }
          })
        );
        setGlobalProfileImages(Object.fromEntries(images));
      }
    };

    fetchImages();
  }, [GlobalUserlist, accessToken]);


  const showInToplistMyCompany = useMemo(() => {
    if (activePage !== TabOptionsLeaderboard.myCompany) {
      return null;
    }

    if (activePage === TabOptionsLeaderboard.myCompany && settings.data?.showInToplistMyCompany === false) {
      return (
        <TopListSettings />
      )
    }

    if (LocalUserlist && LocalUserlist.userActivity.length <= 0) {
      return (
        <EmptyState iconType={IconType.Users} padding>
          <FormattedMessage
            id="competitionTopListEmptyState"
            defaultMessage="No users found"
            description="Empty state for competition top list"
          />
        </EmptyState>
      );
    }

    return (
      <Fragment>
        <>
          <TopThreeContainer>
            {localProfileData.LocaltopProfiles?.slice(0, 3).map((profile, index) => (
              <LeadershipProfileWrapper
                key={index}
                className={
                  index === 0
                    ? 'profile-first'
                    : index === 1
                      ? 'profile-second'
                      : 'profile-third'
                }
              >
                <LedarshipProfile
                topThree={true}
                  rank={profile.rank.toString()}
                  profileImage={
                    LocalProfileImages[profile.userId] || defaultprofile
                  }
                  profileName={`${profile.firstName} ${profile.lastName}`}
                  company={profile.companyName}
                  wellrpoint={
                    isWellrSteps
                      ? profile.steps.toString()
                      : profile.points.toString()
                  }
                  wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                  rankSize={
                    profile.rank.toString() === '1'
                      ? 'first'
                      : profile.rank.toString() === '2'
                        ? 'second'
                        : 'third'
                  }
                  noBackground={true}
                  isMe={profile.userId === userId}
                />
              </LeadershipProfileWrapper>
            ))}
          </TopThreeContainer>

          {localProfileData.LocaltopProfiles?.slice(3, profilesToShow).map(
            (profile, index) => (
              <LedarshipProfile
                key={index + 3}
                rank={profile.rank.toString()}
                profileImage={
                  LocalProfileImages[profile.userId] || defaultprofile
                }
                profileName={`${profile.firstName} ${profile.lastName}`}
                company={profile.companyName}
                wellrpoint={
                  isWellrSteps
                    ? profile.steps.toString()
                    : profile.points.toString()
                }
                wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                isFirst={index === 0}
                isMe={profile.userId === userId}
              />
            )
          )}

          {showMore &&
            localProfileData.remainingLocalProfiles
              ?.slice(0, profilesToShow - 10)
              .map((profile, index) => (
                <LedarshipProfile
                  key={index + 10}
                  rank={profile.rank.toString()}
                  profileImage={
                    LocalProfileImages[profile.userId] || defaultprofile
                  }
                  profileName={`${profile.firstName} ${profile.lastName}`}
                  company={profile.companyName}
                  wellrpoint={
                    isWellrSteps
                      ? profile.steps.toString()
                      : profile.points.toString()
                  }
                  wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                  isFirst={false}
                  isLast={false}
                  isMe={profile.userId === userId}
                />
              ))}

          {localProfileData.MylocaluserRank > 10 && localProfileData.myLocaluserProfile && (
            localProfileData.MylocaluserRank > (profilesToShow) && (
              <LedarshipProfile
                rank={String(localProfileData.myLocaluserProfile.rank)}
                profileImage={
                  LocalProfileImages[localProfileData.myLocaluserProfile.userId] ||
                  defaultprofile
                }
                profileName={`${localProfileData.myLocaluserProfile.firstName} ${localProfileData.myLocaluserProfile.lastName}`}
                company={localProfileData.myLocaluserProfile.companyName}
                wellrpoint={
                  isWellrSteps
                    ? localProfileData.myLocaluserProfile.steps.toString()
                    : localProfileData.myLocaluserProfile.points.toString()
                }
                wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                isFirst={false}
                isLast={true}
                isMe={true}
              />
            )
          )}

          {localProfileData.remainingLocalProfiles.length > profilesToShow - 10 && (
            <BackButtonWrapper>
              <ExpandButton onClick={handleShowMore}>
                {intl.formatMessage({ id: 'showMoreButton' })}
              </ExpandButton>
            </BackButtonWrapper>
          )}
        </>
      </Fragment>
    );
  }, [activePage,
    settings.data?.showInToplistMyCompany,
    localProfileData.LocaltopProfiles,
    localProfileData.MylocaluserRank,
    localProfileData.myLocaluserProfile,
    localProfileData.remainingLocalProfiles,
    profilesToShow,
    showMore,
    LocalUserlist,
    LocalProfileImages,
    isWellrSteps,
    intl]);


  const showInToplistAllCompanies = useMemo(() => {
    if (activePage !== TabOptionsLeaderboard.allCompanies) {
      return null;
    }

    if (activePage === TabOptionsLeaderboard.allCompanies && settings.data?.showInToplistAllCompanies === false) {
      return (
        <TopListSettingsAllCompanies />
      )
    }

    if (GlobalUserlist && GlobalUserlist.userActivity.length <= 0) {
      return (
        <EmptyState iconType={IconType.Users} padding>
          <FormattedMessage
            id="competitionTopListEmptyState"
            defaultMessage="No users found"
            description="Empty state for competition top list"
          />
        </EmptyState>
      );
    }

    return (
      <Fragment>
        <>
          <TopThreeContainer>
            {globalProfileData.GlobaltopProfiles?.slice(0, 3).map((profile, index) => (
              <LeadershipProfileWrapper
                key={index}
                className={
                  index === 0
                    ? 'profile-first'
                    : index === 1
                      ? 'profile-second'
                      : 'profile-third'
                }
              >
                <LedarshipProfile
                  topThree={true}
                  rank={profile.rank.toString()}
                  profileImage={
                    GlobalProfileImages[profile.userId] || defaultprofile
                  }
                  profileName={`${profile.firstName} ${profile.lastName}`}
                  company={profile.companyName}
                  wellrpoint={
                    isWellrSteps
                      ? profile.steps.toString()
                      : profile.points.toString()
                  }
                  wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                  rankSize={
                    profile.rank.toString() === '1'
                      ? 'first'
                      : profile.rank.toString() === '2'
                        ? 'second'
                        : 'third'
                  }
                  noBackground={true}
                  isMe={profile.userId === userId}
                />
              </LeadershipProfileWrapper>
            ))}
          </TopThreeContainer>

          {globalProfileData.GlobaltopProfiles?.slice(3, profilesToShow).map(
            (profile, index) => (
              <LedarshipProfile
                key={index + 3}
                rank={profile.rank.toString()}
                profileImage={
                  GlobalProfileImages[profile.userId] || defaultprofile
                }
                profileName={`${profile.firstName} ${profile.lastName}`}
                company={profile.companyName}
                wellrpoint={
                  isWellrSteps
                    ? profile.steps.toString()
                    : profile.points.toString()
                }
                wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                isFirst={index === 0}
                isMe={profile.userId === userId}
              />
            )
          )}

          {showMore &&
            globalProfileData.remainingGlobalProfiles
              ?.slice(0, profilesToShow - 10)
              .map((profile, index) => (
                <LedarshipProfile
                  key={index + 10}
                  rank={profile.rank.toString()}
                  profileImage={
                    GlobalProfileImages[profile.userId] || defaultprofile
                  }
                  profileName={`${profile.firstName} ${profile.lastName}`}
                  company={profile.companyName}
                  wellrpoint={
                    isWellrSteps
                      ? profile.steps.toString()
                      : profile.points.toString()
                  }
                  wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                  isLast={
                    index ===
                    localProfileData.remainingLocalProfiles?.slice(
                      0,
                      profilesToShow - 10
                    ).length -
                    1
                  }
                  isMe={profile.userId === userId}
                />
              ))}

          {globalProfileData.MyglobaluserRank > 10 && globalProfileData.myGlobaluserProfile && (
            globalProfileData.MyglobaluserRank > (profilesToShow) && (
              <LedarshipProfile
                rank={String(globalProfileData.myGlobaluserProfile.rank)}
                profileImage={
                  GlobalProfileImages[globalProfileData.myGlobaluserProfile.userId] ||
                  defaultprofile
                }
                profileName={`${globalProfileData.myGlobaluserProfile.firstName} ${globalProfileData.myGlobaluserProfile.lastName}`}
                company={globalProfileData.myGlobaluserProfile.companyName}
                wellrpoint={
                  isWellrSteps
                    ? globalProfileData.myGlobaluserProfile.steps.toString()
                    : globalProfileData.myGlobaluserProfile.points.toString()
                }
                wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                isFirst={false}
                isLast={true}
                isMe={true}
              />
            )
          )}

          {globalProfileData.remainingGlobalProfiles.length > profilesToShow - 10 && (
            <BackButtonWrapper>
              <ExpandButton onClick={handleShowMore}>
                {intl.formatMessage({ id: 'showMoreButton' })}
              </ExpandButton>
            </BackButtonWrapper>
          )}
        </>
      </Fragment>
    )
  }, [activePage,
    settings.data?.showInToplistAllCompanies,
    globalProfileData.GlobaltopProfiles,
    globalProfileData.MyglobaluserRank,
    globalProfileData.myGlobaluserProfile,
    globalProfileData.remainingGlobalProfiles,
    profilesToShow,
    showMore,
    GlobalProfileImages,
    localProfileData.MylocaluserRank,
    localProfileData.remainingLocalProfiles,
    GlobalUserlist,
    isWellrSteps]);

  if (!GlobalUserlist || !LocalUserlist) {
    return <Loader />;
  }


  return (
    <Fragment>
      <LeadershipToggleButtons
        activePage={activePage}
        handlePageSwitch={handlePageSwitch}
        isWellrSteps={isWellrSteps}
        setIsWellrSteps={setIsWellrSteps}
      />

      <CardContainer>
        {showInToplistAllCompanies}
        {showInToplistMyCompany}
      </CardContainer>
    </Fragment >
  );
}

export default Leaderboard;