import React, { FC, useEffect, useMemo, useState } from 'react';
import { BadgeDetailsContainer, Overlay, IconContainer, DiagonalOverlay, LabelContainer, LabelText, ButtonContainer } from './styles';
import { BadgeformatDate } from 'utils/date';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon, { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Button';
import { useGetUserNotViewedAchievementsQuery } from 'store/userAchievementService/userAchievementService';
import useAchievementsData from 'hooks/useAchievementsData';
import { getImage } from 'utils/asset';
import { useLocalizedText } from 'hooks/useLocalizedText';

const AchievementBadgePopup: FC = ({
}) => {
  // State
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  // Hooks
  const getText = useLocalizedText();
  const intl = useIntl();
  const { data } = useGetUserNotViewedAchievementsQuery(undefined, {
    refetchOnFocus: true,
    pollingInterval: 1000 * 60 * 5,
  });
  const {
    handleUpdateAchievement,
  } = useAchievementsData();

  useEffect(() => {
    if (data && data.length > 0) {
      setIsPopupOpen(true);
    }
  }, [data]);

  useEffect(() => {
    document.body.style.overflow = isPopupOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isPopupOpen]);

  const achievementDetails = data?.[0]?.achievementDetails;
  const completed = data?.[0]?.completed;
  const image = useMemo(() => {
    if (achievementDetails?.image) {
      const imageData = getImage(achievementDetails?.image);
      return {
        ...imageData,
        src: imageData?.src.split('?')[0]
      };
    }
    return null;
  }, [achievementDetails?.image]);

  const handleClosePopup = async () => {
    if (!data) return;

    const updatedBadge = {
      ...data[0],
      isCompletionViewed: true
    };

    if (handleUpdateAchievement) {
      try {
        await handleUpdateAchievement(updatedBadge);
        setIsPopupOpen(false);
      } catch (error) {
        console.error('Error updating achievement:', error);
      }
    } else {
      console.error('handleUpdateAchievement is not defined');
    }
  };

  if (!isPopupOpen) return null;


  return (
    <Overlay>
      <BadgeDetailsContainer>

        <div className="modal-content">
          <div>
            <div className="badge-image">
              <img src={image!.src} alt={getText(achievementDetails?.title)} />
              <LabelContainer>
                <DiagonalOverlay />
                <LabelText>NEW</LabelText>
              </LabelContainer>
            </div>
            <div className="badge-details">
              <h6>
                <IconContainer>
                  <Icon type={IconType.Calendar} color="grayText" size={22} />
                </IconContainer>
                <span>{intl.formatMessage({ id: 'pageUnlockedDate' })} {BadgeformatDate(completed ?? '')}</span>
              </h6>
                <br />
              <h2>{intl.formatMessage({ id: 'newUnlockedAchievement' })}
                {' ' + getText(achievementDetails?.title)}
                <br />
              </h2>
              <p>
                {getText(achievementDetails?.description)}
                <br />
                <strong>
                  {intl.formatMessage({ id: 'badgeCongratulationsTitle' }) + ' '}
                </strong>
                {intl.formatMessage({ id: 'badgeUnlockedDescription' })}
              </p>
            </div>
          </div>

          <ButtonContainer>
            <Button
              size="large"
              color="white"
              background="blue"
              border="backgroundDark"
              rounded
              width='100%'
              onClick={() => handleClosePopup()}
            >
              <FormattedMessage
                id={'continueButton'}
              />
            </Button>
          </ButtonContainer>
        </div>
      </BadgeDetailsContainer>
    </Overlay>
  );
};

export default AchievementBadgePopup;