import { AchievementResponseType } from 'models/achievement/achievement';
import { UserAchievementResponseType } from 'models/userachievement/userachievement';
import { useMemo } from 'react';
import { useGetAchievementsQuery } from 'store/achievementService/achievementService';
import { useGetUserAchievementsQuery, useUpdateUserAchievementMutation } from 'store/userAchievementService/userAchievementService';

const useAchievementsData = (pollingInterval?: number) => {
  const {
    data: achievementsData,
    error: achievementsError,
    isLoading: isLoadingAchievements,
  } = useGetAchievementsQuery(undefined, {
    pollingInterval,
  });

  const {
    data: userAchievementsData,
    error: userAchievementsError,
    isLoading: isLoadingUserAchievements,
  } = useGetUserAchievementsQuery(undefined, {
    pollingInterval,
  });

  const [updateUserAchievement] = useUpdateUserAchievementMutation();

  const countAllMyBadges = useMemo(() =>
    userAchievementsData && Array.isArray(userAchievementsData)
      ? userAchievementsData.length
      : 0, [userAchievementsData]);

  const countAllBadges = useMemo(() =>
    achievementsData && Array.isArray(achievementsData)
      ? achievementsData.length
      : 0, [achievementsData]);

  const allLockedBadges = useMemo(() =>
    achievementsData && Array.isArray(achievementsData)
      ? achievementsData
        .filter((achievement: AchievementResponseType) => {
          return !(
            userAchievementsData &&
            Array.isArray(userAchievementsData) &&
            userAchievementsData.some(
              (userAchievement: UserAchievementResponseType) =>
                userAchievement.achievementSlug === achievement.slug
            )
          );
        })
        .sort((a, b) => a.title['sv'].localeCompare(b.title['sv']))
        .map((achievement: AchievementResponseType) => ({
          ...achievement,
          isLocked: true,
        }))
      : [], [achievementsData, userAchievementsData]);


  const userAchievementsMap = useMemo(() =>
    userAchievementsData && Array.isArray(userAchievementsData)
      ? userAchievementsData.reduce((map, userAchievement) => {
        map[userAchievement.achievementSlug] = userAchievement.completed;
        return map;
      }, {} as Record<string, string>)
      : {}, [userAchievementsData]);


  const allUnlocked = useMemo(() =>
    achievementsData && Array.isArray(achievementsData)
      ? achievementsData
        .filter(
          (achievement: AchievementResponseType) =>
            userAchievementsMap[achievement.slug]
        )
        .map((achievement: AchievementResponseType) => {
          const userAchievement = userAchievementsData?.find(
            (userAchievement: UserAchievementResponseType) =>
              userAchievement.achievementSlug === achievement.slug
          );

          return {
            ...achievement,
            isLocked: false,
            completed: userAchievementsMap[achievement.slug],
            IsCompletionViewed: userAchievement
              ? userAchievement.isCompletionViewed
              : null,
          };
        })
      : [], [achievementsData, userAchievementsMap, userAchievementsData]);

  const combinedBadges = useMemo(() => {
    const unlockedBadges = allUnlocked || [];
    const lockedBadges = allLockedBadges || [];

    // Take all unlocked badges (up to 5)
    const selectedUnlocked = unlockedBadges
      .sort((a, b) => new Date(b.completed).getTime() - new Date(a.completed).getTime())
      .slice(0, 5);

    // If we have less than 5 unlocked, add locked badges
    const remainingSlots = 5 - selectedUnlocked.length;
    const selectedLocked = remainingSlots > 0 ? lockedBadges.slice(0, remainingSlots) : [];

    return [...selectedUnlocked, ...selectedLocked];
  }, [allUnlocked, allLockedBadges]);

  if (isLoadingAchievements || isLoadingUserAchievements) {
    return { loading: true };
  }

  if (achievementsError || userAchievementsError) {
    return { error: true };
  }

  const handleUpdateAchievement = async (
    partialUserAchievement: Partial<UserAchievementResponseType>
  ) => {
    try {

      const { achievementSlug } = partialUserAchievement;

      if (!achievementSlug) {
        console.error('Missing achievementSlug');
        return;
      }

      const existingUserAchievement = userAchievementsData?.find(
        (userAchievement: UserAchievementResponseType) =>
          userAchievement.achievementSlug === achievementSlug
      );

      if (!existingUserAchievement) {
        console.error(
          `No existing achievement found for slug: ${achievementSlug}`
        );
        return;
      }

      const updatedUserAchievement: UserAchievementResponseType = {
        ...existingUserAchievement,
        ...partialUserAchievement,
        updated: new Date().toISOString(),

        completed:
          existingUserAchievement.completed || new Date().toISOString(),
      };

      await updateUserAchievement(updatedUserAchievement).unwrap();

    } catch (error) {
      console.error('Failed to update achievement:', error);
    }
  };

  return {
    userAchievementsData,
    countAllBadges,
    countAllMyBadges,
    allLockedBadges,
    allUnlocked,
    combinedBadges,
    handleUpdateAchievement,
    loading: false,
  };
};

export default useAchievementsData;
