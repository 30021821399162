import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import christmasLottie from 'assets/animations/snow.json';
import valentineLottie from 'assets/animations/valentine.json';

import {
  getRankColor,
  getGoalDataFromContestGoal,
  getSubGoalProgress,
} from 'utils/contest';
import { ContestGoal, GoalData, SpecialContestType, SubGoalData } from 'models';
import { googleMapsApiKey } from 'config';

import MapSubGoalMarkers from 'components/fragments/MapSubGoalMarkers';
import MapGoalMarker from 'components/fragments/MapGoalMarker';
import Icon, { IconType } from 'components/UI/Icon';
import { MapSettingsContainer, StyledLottie } from './styles';
import useModal from 'hooks/useModal';
import ContentModal from 'components/modals/ContentModal';
import { useIntl } from 'react-intl';
import ToggleCard from 'components/UI/Cards/ToggleCard';

type Props = {
  goals: ContestGoal[];
  participants: Array<{
    id: string;
    total: number;
  }>;
  myParticipantId?: string;
  useRankColors: boolean;
  specialCompetition: SpecialContestType;
};

const mapSettingsID = 'mapSettingsContainer' as const;

const ContestMap: FC<Props> = ({
  goals,
  participants,
  myParticipantId,
  useRankColors,
  specialCompetition,
}) => {
  const theme = useTheme();

  const [isHoveringMapSettings, setIsHoveringMapSettings] = useState(false);
  const [showMapGoals, setShowMapGoals] = useState(true);
  const [showMapSubGoals, setShowMapSubGoals] = useState(true);
  const intl = useIntl();
  const { modalOpen, onCloseModal, onOpenModal } = useModal();

  const christmasCompetition = specialCompetition === SpecialContestType.Christmas;
  const pinkCompetition = specialCompetition === SpecialContestType.Pink;
  const valentineCompetition = specialCompetition === SpecialContestType.Valentine;


  // Hooks
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  const renderPolylineIcons = useCallback(
    (goal: GoalData, subGoal: SubGoalData) => {
      const polylineIcons: google.maps.IconSequence[] = [];
      participants.forEach((item, i) => {
        const progress = getSubGoalProgress(item.total, subGoal, goal.subGoals);
        if (progress !== null) {
          const isMine = item.id === myParticipantId;
          polylineIcons.push({
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 8,
              strokeOpacity: 1,
              strokeWeight: 1,
              strokeColor: theme.colors.borderLight,
              fillColor: useRankColors
                ? getRankColor(theme, i + 1, isMine)
                : theme.colors[isMine ? 'orangeDark' : 'blue'],
              fillOpacity: 1,
            },
            offset: `${progress.toFixed(2)}%`,
          });
        }
      });
      return polylineIcons;
    },
    [participants, myParticipantId, theme, useRankColors]
  );

  // Goal parts
  const goalData = useMemo<GoalData[]>(
    () => goals.map(getGoalDataFromContestGoal),
    [goals]
  );

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      const bounds = new google.maps.LatLngBounds();
      goalData.forEach((goal) => {
        goal.subGoals.forEach((subGoal) => {
          bounds.extend({
            lat: subGoal.startLat || 0,
            lng: subGoal.startLng || 0,
          });
          bounds.extend({
            lat: subGoal.stopLat || 0,
            lng: subGoal.stopLng || 0,
          });
        });
      });
      map.fitBounds(bounds);
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        document.getElementById(mapSettingsID) as HTMLElement
      );
    },
    [goalData]
  );

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <GoogleMap
        zoom={3}
        onLoad={onLoad}
        mapContainerStyle={{
          width: '100%',
          height: '400px',
        }}
        options={{
          disableDefaultUI: true,
          clickableIcons: false,
          zoomControl: true,
        }}
      >
        {christmasCompetition &&
          <StyledLottie
            loop={true}
            animationData={christmasLottie}
          />
        }
        {valentineCompetition &&
          <StyledLottie
            loop={true}
            zoom={0.01}
            animationData={valentineLottie}
          />
        }
        <MapSettingsContainer
          id={mapSettingsID}
          onMouseEnter={() => setIsHoveringMapSettings(true)}
          onMouseLeave={() => setIsHoveringMapSettings(false)}
          onClick={onOpenModal}
        >
          <Icon
            type={IconType.MapSettings}
            color="mapIconColor"
            isHovering={isHoveringMapSettings}
            hoverColor="black"
          />
        </MapSettingsContainer>
        {goalData.map((goal) => (
          <Fragment key={goal.id}>
            {goal.subGoals.map((subGoal) => (
              <Polyline
                key={subGoal.id}
                options={{
                  icons: [...renderPolylineIcons(goal, subGoal)],
                  strokeColor: pinkCompetition ? theme.colors.pink : christmasCompetition || valentineCompetition ? theme.colors.error : theme.colors.blue,
                  strokeOpacity: 0.35,
                  zIndex: 2,
                }}
                path={[
                  {
                    lat: subGoal.startLat || 0,
                    lng: subGoal.startLng || 0,
                  },
                  {
                    lat: subGoal.stopLat || 0,
                    lng: subGoal.stopLng || 0,
                  },
                ]}
              />
            ))}
            {showMapGoals && (
              <MapGoalMarker
                specialContest={specialCompetition}
                position={{
                  lat: goal.stopLat,
                  lng: goal.stopLng,
                }}
              />
            )}
          </Fragment>
        ))}
        {showMapSubGoals && <MapSubGoalMarkers goals={goals} specialContest={specialCompetition} />}
      </GoogleMap>
      <ContentModal
        isOpen={modalOpen}
        onClose={onCloseModal}
        title={intl.formatMessage({
          id: 'mapSettingsModalTitle',
          defaultMessage: 'Map settings',
          description: 'Map settings modal title',
        })}
      >
        <ToggleCard
          title={intl.formatMessage({
            id: 'mapSettingsSubGoalsToggle',
            defaultMessage: 'Show milestones on the map',
            description: 'Map settings goals title',
          })}
          isActive={showMapSubGoals}
          onToggle={() => setShowMapSubGoals(!showMapSubGoals)}
        />
        <br />
        <ToggleCard
          title={intl.formatMessage({
            id: 'mapSettingsGoalsToggle',
            defaultMessage: 'Show goal on the map',
            description: 'Map settings goals title',
          })}
          isActive={showMapGoals}
          onToggle={() => setShowMapGoals(!showMapGoals)}
        />
      </ContentModal>
    </>
  );
};

export default ContestMap;
