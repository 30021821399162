// styles.ts
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ThemeColor } from 'styles';

export const Container = styled.div`
  position: relative;
  height: 50px;
  border-radius: 4px;
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.colors.backgroundDark};
  border-radius: 4px;
  overflow: hidden;
`;

export const Bar = styled(motion.div)<{ color: string }>`
  background: ${({ color, theme }) =>
    color.startsWith('#') ? color : theme.colors[color as ThemeColor]};
  border-radius: 4px 0 0 4px;
  height: 8px;
  width: 100%;
`;

export const ProgressIcon = styled(motion.img)<{ bottom?: number }>`
  position: absolute;
  bottom: ${({ bottom }) => bottom ?? 8}px;
  width: 44px;
  height: 44px;
  max-height: 44px;
  transform: translateX(-80%);
`;

type MarkerProps = {
  isCurrent: boolean;
};

export const Marker = styled.img<MarkerProps>`
  position: absolute;
  color: ${({ theme }) => theme.colors.black};
  width: 24px;
  height: 24px;
  margin-left: -12px;
`;
export const MarkerContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8px;
  display: flex;
  align-items: center;
  z-index: 1;
`;

export const GoalFlag = styled.img`
  position: absolute;
  bottom: 0;
  right: -13px;
  width: 44px;
  height: 44px;
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;
